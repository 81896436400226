import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import FacturaCard from '../FacturaCard'
import Pagination from '../../../../shared/Pagination'

import './facturasContainerDesk.scss'
import useScreenWidth from '../../../../../hooks/useScreenWidth'
import SkeletonFacturaCard from '../SkeletonFacturaCard'

const FacturasContainerDesk = ({ facturasData, currentPage, totalCount, pageSize, onPageChange, isLoading }) => {
  const { t } = useTranslation()
  const screenWidth = useScreenWidth()
  return (
    <section className='facturas-container-desk'>
       <p className='facturas-container-desk__titles'>
        <span className='facturas-container-desk__titles--left-align'>{t('area-personal.facturas.num')}</span>
        <span className='facturas-container-desk__titles--left-align'>{t('area-personal.facturas.pieza')}</span>
        {screenWidth >= 1200 && <>
            <span>{t('area-personal.facturas.presupuesto')}</span>
            <span>{t('area-personal.facturas.precio')}</span>
            <span>{t('area-personal.facturas.envio')}</span>
            <span>{t('area-personal.facturas.total')}</span>
            <span>{t('area-personal.facturas.estado')}</span>
            <span>{t('area-personal.facturas.pago')}</span>
            <span>{t('area-personal.facturas.acciones')}</span>
            </>}
      </p>
      <div className='facturas-container-desk__container-cards'>
      {!isLoading
        ? <>
        {
          facturasData?.map(factura =>
            <FacturaCard key={factura?.numero} factura={factura} />)
        }
        {facturasData?.length < 1 && <div className='facturas-container-desk__container-cards--nodata'>{t('area-personal.facturas.nodata')}</div>}
        </>
        : <><SkeletonFacturaCard/><SkeletonFacturaCard/><SkeletonFacturaCard/><SkeletonFacturaCard/></>
      }
      </div>
      {facturasData?.length > 0 && <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageChange={onPageChange}
        />}
    </section>
  )
}

FacturasContainerDesk.propTypes = {
  facturasData: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default FacturasContainerDesk
