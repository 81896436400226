import { useRef } from 'react'
import './filtroButton.scss'
import PropTypes from 'prop-types'

const FiltroButton = ({ handleFilterBy, option1, option1Text, option2, option2Text }) => {
  const buttonRef = useRef()
  const option1Ref = useRef()
  const option2Ref = useRef()

  const handleOption1 = () => {
    buttonRef.current.classList.remove('filtro-button__right')
    option1Ref.current.classList.remove('filtro-button__option1--primary-text')
    option2Ref.current.classList.add('filtro-button__option2--primary-text')
    handleFilterBy(option1)
  }
  const handleOption2 = () => {
    buttonRef.current.classList.add('filtro-button__right')
    option1Ref.current.classList.add('filtro-button__option1--primary-text')
    option2Ref.current.classList.remove('filtro-button__option2--primary-text')
    handleFilterBy(option2)
  }

  return <div className="filtro-button">
        <div
            ref={option1Ref}
            className="filtro-button__option1"
            onClick={handleOption1}
        >
            {option1Text}
        </div>
        <div
            ref={option2Ref}
            className="filtro-button__option2 filtro-button__option2--primary-text"
            onClick={handleOption2}
        >
            {option2Text}
        </div>
        <div
            ref={buttonRef}
            className="filtro-button__board filtro-button__left"
        ></div>
    </div>
}

FiltroButton.propTypes = {
  handleFilterBy: PropTypes.func.isRequired,
  option1: PropTypes.string.isRequired,
  option2: PropTypes.string.isRequired,
  option1Text: PropTypes.string.isRequired,
  option2Text: PropTypes.string.isRequired
}

export default FiltroButton
