import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { TbTrashXFilled } from 'react-icons/tb'

import './busquedaDropDown.scss'
import { toast } from 'react-toastify'
import Spinner from '../../../../shared/Spinner'

const BusquedaDropDown = ({ submitBusqueda, isLoading }) => {
  const { t } = useTranslation()

  const inputRef = useRef(null)
  const inputRef2 = useRef(null)

  const [busquedaData, setbusquedaData] = useState({
    fechaDesde: '',
    fechaHasta: '',
    precioUnidadDesde: '',
    precioUnidadHasta: '',
    precioImpuestosDesde: '',
    precioImpuestosHasta: ''
  })

  const handleBusquedaData = (e) => {
    let fechaDesde
    let fechaHasta

    if (e.target.name === 'fechaDesde') {
      fechaDesde = new Date(e.target.value)
      fechaHasta = new Date(busquedaData.fechaHasta)
    } else if (e.target.name === 'fechaHasta') {
      fechaDesde = new Date(busquedaData.fechaDesde)
      fechaHasta = new Date(e.target.value)
    }

    if (fechaDesde > fechaHasta) {
      setbusquedaData({
        ...busquedaData, fechaHasta: ''
      })
      return toast.error(t('area-personal.pedidos.busqueda.errorFecha'))
    }

    setbusquedaData({
      ...busquedaData, [e.target.name]: e.target.value
    })
  }

  const handleResetFilters = () => {
    setbusquedaData({
      fechaDesde: '',
      fechaHasta: '',
      precioUnidadDesde: '',
      precioUnidadHasta: '',
      precioImpuestosDesde: '',
      precioImpuestosHasta: ''
    })
  }

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0]
    inputRef.current.setAttribute('max', today)
    inputRef2.current.setAttribute('max', today)
  }, [])

  return <section className='busqueda-drop-down'>
        <span
          onClick={handleResetFilters}
          className='filtros-section__mb-top-btns--borrar search-by-date__dropdown--borrar'>
            <TbTrashXFilled />
            {t('catalogo-piezas.filtros-seccion.borrar-todo')}
         </span>
        <div className='busqueda-drop-down__each-filter'>
            <p className='busqueda-drop-down__each-filter--fecha'>{t('area-personal.pedidos.busqueda.fecha')}</p>
            <input className='busqueda-drop-down__each-filter--input-date' ref={inputRef} max='' type='date' name='fechaDesde' value={busquedaData.fechaDesde} onChange={handleBusquedaData} />
            <p className='busqueda-drop-down__each-filter--hasta'>{t('area-personal.pedidos.busqueda.hasta')}</p>
            <input className='busqueda-drop-down__each-filter--input-date' ref={inputRef2} max='' type='date' name='fechaHasta' value={busquedaData.fechaHasta} onChange={handleBusquedaData} />
        </div>
        <div className='busqueda-drop-down__each-filter'>
            <p>{t('area-personal.pedidos.busqueda.precio')}</p>
            <input type='number' name='precioUnidadDesde' value={busquedaData.precioUnidadDesde} onChange={handleBusquedaData}/>
            <p className='busqueda-drop-down__each-filter--hasta'>{t('area-personal.pedidos.busqueda.hasta')}</p>
            <input type='number' name='precioUnidadHasta' value={busquedaData.precioUnidadHasta} onChange={handleBusquedaData}/>
        </div>
        <div className='busqueda-drop-down__each-filter'>
            <p>{t('area-personal.pedidos.busqueda.impuestos')}</p>
            <input type='number' name='precioImpuestosDesde' value={busquedaData.precioImpuestosDesde} onChange={handleBusquedaData}/>
            <p className='busqueda-drop-down__each-filter--hasta'>{t('area-personal.pedidos.busqueda.hasta')}</p>
            <input type='number' name='precioImpuestosHasta' value={busquedaData.precioImpuestosHasta} onChange={handleBusquedaData}/>
        </div>
        <div className='busqueda-drop-down__button'>
            <button disabled={isLoading} onClick={() => submitBusqueda(busquedaData)}>{isLoading ? <Spinner className='spinner-busqueda-drop-down'/> : t('area-personal.pedidos.busqueda.buscar')}</button>
        </div>
    </section>
}

BusquedaDropDown.propTypes = {
  submitBusqueda: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default BusquedaDropDown
