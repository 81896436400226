import DetalleCarrito from '../../shared/DetalleCarrito'
import { BiArrowBack } from 'react-icons/bi'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import './resumenCarrito.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import WhatsAppSection from '../../shared/WhatsAppSection'
import TotalPedido from '../../shared/TotalPedido'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { checkSusceptibleWearingCasco } from '../../../utils'
import { OkMessage } from '../../shared/Alert'
import { getCookie } from '../../../services/cookies'
import { io } from 'socket.io-client'
import { getValidatedCart } from '../../../services/api'
import { updateCart } from '../../../redux/actions/cart'
import { Alert } from '@mui/material'

const ResumenCarrito = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart)
  const [piezasStatusChecked, setPiezasStatusChecked] = useState([])
  const [isPartsSusceptibleWearingCasco, setIsPartsSusceptibleWearingCasco] = useState(false)
  const [isModalNoValidatedPresupuestoOpen, setIsModalNoValidatedPresupuestoOpen] = useState(false)

  const submitOrder = () => {
    if (isPartsSusceptibleWearingCasco) return handleConfirm()
    const isStockDisponible = piezasStatusChecked.filter(part => part.estado !== 1 && part.estado !== 3)
    if (isStockDisponible?.length > 0) return toast.error(t('errors.stock.1'))
    if (piezasStatusChecked?.length === 0) return toast.error(t('errors.stock.2'))
    const url = process.env.REACT_APP_CHECKOUT + cart?.checkout_id
    const link = document.createElement('a')
    link.href = url

    link.click()
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (cart?.validado === 0) {
      const partsSusceptibleCasco = (cart?.piezas).filter(pieza => checkSusceptibleWearingCasco({ notapublica: pieza?.observaciones || '', tipo_pieza_id: pieza?.tipo_pieza?.id }))
      if (partsSusceptibleCasco?.length > 0) return setIsPartsSusceptibleWearingCasco(true)
    } else {
      setIsPartsSusceptibleWearingCasco(false)
    }
  }, [cart?.piezas])

  // socket check validate cart
  useEffect(() => {
    if (cart?.validado === 0) {
      let intervalId
      const socket = io(process.env.REACT_APP_API_BASE, {
        auth: { token: getCookie('token_tienda_recomotor') }
      })
      const checkStatus = () => {
        socket.emit('checkStatusCart', cart?.id)
      }
      socket.on('connect', () => {
        checkStatus()
        intervalId = setInterval(checkStatus, process.env.REACT_APP_TIME_CASCO_REQ)
      })
      socket.on('disconnect', () => {
        clearInterval(intervalId)
      })
      socket.on('statusChange', async (newStatusCart) => {
        if (newStatusCart === 1) {
          const res = await getValidatedCart({ presupuestoId: cart?.id })
          if (res?.status !== 200) {
            return toast.error(t('errors.1'))
          }
          dispatch(updateCart(res.data))
          setIsPartsSusceptibleWearingCasco(false)
          clearInterval(intervalId)
          window.scrollTo(0, 0)
          setIsModalNoValidatedPresupuestoOpen(true)
          socket.disconnect()
        }
      })
      // Limpiar la conexión al desmontar el componente
      return () => {
        clearInterval(intervalId)
        socket.disconnect()
      }
    }
  }, [cart?.validado])

  const handleConfirm = () => {
    window.scrollTo(0, 0)
    setIsModalNoValidatedPresupuestoOpen(true)
  }

  return <div className='resumen-carrito'>
        <section className='resumen-carrito__detalle'>
            <div className='resumen-carrito__detalle--return'>
                <button onClick={() => navigate(-1)}><BiArrowBack/> {t('carrito.detalle.continuar')}</button>
            </div>
            <h1 className='resumen-carrito__detalle--title'>{t('carrito.detalle.title')}</h1>
            <DetalleCarrito
              piezasStatusChecked={piezasStatusChecked}
              setPiezasStatusChecked={setPiezasStatusChecked}
              className='resumen-carrito__detalle--piezas'
              isPartsSusceptibleWearingCasco={isPartsSusceptibleWearingCasco} />
        </section>
        <section className='resumen-carrito__pedido-section'>
            <div className='resumen-carrito__pedido-section-div'>
                <TotalPedido className='resumen-carrito__pedido-section--total'/>
                {!cart?.validado && <Alert severity="warning">{t('carrito.detalle.no-validated-3')}</Alert>}
            </div>
            <button className={isPartsSusceptibleWearingCasco ? 'resumen-carrito__pedido-section--disabled-btn' : ''} onClick={submitOrder}><span><MdOutlineKeyboardArrowRight/></span>{t('carrito.pedido.submit')}</button>
        </section>
        {isModalNoValidatedPresupuestoOpen && <OkMessage text={cart?.validado ? t('carrito.detalle.validated-1') : t('carrito.detalle.no-validated-1')} text2={cart?.validado ? t('carrito.detalle.validated-2') : t('carrito.detalle.no-validated-2')} setIsOkMessageOpen={setIsModalNoValidatedPresupuestoOpen} isOkMessage={false} isCartCheck={cart?.validado !== 0} />}
        <WhatsAppSection />
    </div>
}

export default ResumenCarrito
