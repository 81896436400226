import React, { useEffect, useState } from 'react'
import logo1 from '../../../images/login/logo1.png'
import { HiOutlineMenu } from 'react-icons/hi'
import { IoMdClose } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { IoReturnDownBackSharp } from 'react-icons/io5'

import './navComercial.scss'
import { Link } from 'react-router-dom'

const NavComercial = () => {
  const { t } = useTranslation()
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [pathname, setPathname] = useState('/')

  const handleChangePath = (newPathName) => {
    setPathname(newPathName)
    setIsNavOpen(false)
  }
  useEffect(() => {
    handleChangePath(window.location.pathname)
  }, [window.location.pathname])
  return (
    <nav className='navbar navcomercial'>
        <div className='navcomercial__logo'>
          <img src={logo1} alt='Recomotor' />
          <p>{t('comercial.title')}</p>
        </div>
        <button
                className="navbar__hamburger"
                onClick={() => {
                  setIsNavOpen(!isNavOpen)
                }}
            >
                { isNavOpen ? <IoMdClose/> : <HiOutlineMenu/>}
        </button>
        <div
            className={
              isNavOpen
                ? 'navbar__menu navbar__menu--expanded'
                : 'navbar__menu'
            }
            >
                <ul>
                    <li className={pathname === '/comercial' ? 'active' : ''}>
                        <Link to="/comercial">{t('comercial.nav.alta')}</Link>
                    </li>
                    <li className={pathname === '/comercial/reenvio-email' ? 'active' : ''}>
                        <Link to="/comercial/reenvio-email">{t('comercial.nav.email')}</Link>
                    </li>
                    <li className={pathname === '/comercial/listados' ? 'active' : ''}>
                        <Link to="/comercial/listados">{t('comercial.nav.listado')}</Link>
                    </li>
                    <li>
                      <Link to="/" >{t('comercial.nav.volver')} <IoReturnDownBackSharp /></Link>
                    </li>
                </ul>
            </div>
      </nav>
  )
}

export default NavComercial
