import { useState } from 'react'
import imgDefault from '../../../images/placeholder.png'
import PropTypes from 'prop-types'

const Image = ({ foto, className = '' }) => {
  const [isImgDefault, setIsImgDefault] = useState(false)
  return (
    <>
    {foto
      ? <img
          src={foto.startsWith('http') ? foto : `https://d2g2xclmj7afe.cloudfront.net/${foto}`}
          onError={(e) => {
            setIsImgDefault(true)
            e.target.src = imgDefault
          }}
          className={`${className} ${isImgDefault ? 'img-default' : ''}`}
          alt='img'
        />
      : <img src={imgDefault} className={`img-default ${className}`} alt='img' />}
  </>)
}

Image.propTypes = {
  foto: PropTypes.string,
  className: PropTypes.string
}

export default Image
