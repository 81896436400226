import { useTranslation } from 'react-i18next'
import PedidoCard from '../PedidoCard'

import './pedidosContainerMob.scss'
import PropTypes from 'prop-types'
import SkeletonPedidoCard from '../SkeletonPedidoCard'

const PedidosContainerMob = ({ pedidosData, observerTarget, setIsSeguimientoOpen, setIsDetallesPedidoOpen, setPedidoToShowInfo, startNuevaIncidencia, isLoading }) => {
  const { t } = useTranslation()
  return <section className='pedidos-container-mob'>
        <p className='pedidos-container-mob__header'>
            <span>{t('area-personal.pedidos.pedido')}</span>
            <span>{t('area-personal.pedidos.pieza')}</span>
        </p>
        {pedidosData?.map(pedido =>
            <PedidoCard
                setIsSeguimientoOpen={setIsSeguimientoOpen}
                setIsDetallesPedidoOpen={setIsDetallesPedidoOpen}
                setPedidoToShowInfo={setPedidoToShowInfo}
                startNuevaIncidencia={startNuevaIncidencia}
                key={pedido?.id}
                pedido={pedido}/>)}
        {!isLoading && pedidosData?.length < 1 && <div className='facturas-container-mob__container-cards--nodata'>{t('area-personal.pedidos.nodata')}</div>}
        {isLoading && <><SkeletonPedidoCard/><SkeletonPedidoCard/><SkeletonPedidoCard/><SkeletonPedidoCard/></>}
        <div ref={observerTarget}></div>
    </section>
}

PedidosContainerMob.propTypes = {
  pedidosData: PropTypes.array.isRequired,
  observerTarget: PropTypes.any.isRequired,
  setIsSeguimientoOpen: PropTypes.func.isRequired,
  setIsDetallesPedidoOpen: PropTypes.func.isRequired,
  setPedidoToShowInfo: PropTypes.func.isRequired,
  startNuevaIncidencia: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default PedidosContainerMob
