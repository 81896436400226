import '../skeletonEstadoRow.scss'
const SkeletonEstadoRow = () => {
  return (
    <p className='modal-ver-incidencia__container--card--row-estado skeleton-estado-row'>
      <span className='modal-ver-incidencia__container--card--row-estado--fecha skeleton-estado-row__fecha detalle-incidencia-loading-animation'>
      </span>
      <span className='modal-ver-incidencia__container--card--row-estado--fecha skeleton-estado-row__fecha-container'>
          <span className='skeleton-estado-row__fecha-container--fecha2 detalle-incidencia-loading-animation'></span>
          <span className='skeleton-estado-row__fecha-container--fecha3 detalle-incidencia-loading-animation'></span>
      </span>
      <span className='modal-ver-incidencia__container--card--row-estado--black-color skeleton-estado-row__estado detalle-incidencia-loading-animation'>
      </span>
      <span className='modal-ver-incidencia__container--card--row-estado--comentario skeleton-estado-row__comentario'>
        <span className='skeleton-estado-row__comentario--title detalle-incidencia-loading-animation'></span>
        <span className='skeleton-estado-row__comentario--text detalle-incidencia-loading-animation'></span>
      </span>
    </p>
  )
}

export default SkeletonEstadoRow
