import { useDispatch, useSelector } from 'react-redux'
import { addToFavoritesRequest, deleteToFavoritesRequest } from '../../../services/api'
import { addToFavorites, removeToFavorites } from '../../../redux/actions/favorites'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import './addToFavoritosBtn.scss'

const AddToFavoritosBtn = ({ pieza, classNameBtn = '', classNameDisabled = '' }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const favoritosArr = useSelector(state => state.favorites)
  const cart = useSelector(state => state.cart)

  const deleteToFavorites = async () => {
    const res = await deleteToFavoritesRequest({ piezaStockId: pieza?.id })
    if (res?.status === 200) {
      return dispatch(removeToFavorites(pieza?.id))
    } else {
      return toast.error(t('errors.favoritos.2'))
    }
  }

  const handleAddToFavorites = async (e) => {
    e.stopPropagation()

    if (cart?.piezas?.find(el => el?.pieza_integracion_id === pieza?.id)) return

    const isAlreadyAdded = favoritosArr?.find(el => el?.id === pieza?.id)
    if (!isAlreadyAdded) {
      const res = await addToFavoritesRequest({ pieza })
      if (res?.status === 200) {
        return dispatch(addToFavorites(pieza))
      } else {
        return toast.error(t('errors.favoritos.1'))
      }
    } else {
      deleteToFavorites()
    }
  }

  return (
    <p className={`add-to-favoritos-btn ${classNameBtn} ${cart?.piezas?.find(el => el?.pieza_integracion_id === pieza?.id) ? (classNameDisabled + ' add-to-favoritos-btn__disabled') : ''}`} onClick={handleAddToFavorites}>
      {favoritosArr && favoritosArr.find(el => el?.id === pieza?.id)
        ? <span className='add-to-favoritos-btn__added'>{t('area-personal.favoritos.btn-active')}</span>
        : <span>{t('area-personal.favoritos.btn')}</span>
      }
    </p>
  )
}

AddToFavoritosBtn.propTypes = {
  pieza: PropTypes.object.isRequired,
  classNameBtn: PropTypes.string,
  classNameDisabled: PropTypes.string
}

export default AddToFavoritosBtn
