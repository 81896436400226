import { types } from '../types'

export const updateCart = (cartData) => ({
  type: types.updateCart,
  payload: cartData
})

export const addToCart = (pieza) => ({
  type: types.addToCart,
  payload: pieza
})

export const removeToCart = (piezaId) => ({
  type: types.removeToCart,
  payload: piezaId
})

export const resetCartState = () => ({
  type: types.resetCartState
})
