import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md'
import PropTypes from 'prop-types'
import { TbTrashXFilled } from 'react-icons/tb'

import './searchByDate.scss'
import Spinner from '../Spinner'

const SearchByDate = ({ searchParams, handleSearchParams, handleResetDates, submitSearch, isLoading }) => {
  const { t } = useTranslation()
  const [isBusquedaOpen, setIsBusquedaOpen] = useState(false)
  const inputRef = useRef()
  const inputRef2 = useRef()

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0]
    inputRef.current && inputRef.current.setAttribute('max', today)
    inputRef2.current && inputRef2.current.setAttribute('max', today)
  }, [inputRef.current, inputRef2.current])

  return (
    <section className='search-by-date'>
      <div className='search-by-date__button'>
        <p onClick={() => setIsBusquedaOpen(!isBusquedaOpen)}>
            {isBusquedaOpen ? <MdKeyboardArrowDown/> : <MdKeyboardArrowRight/>}
            <span>{t('area-personal.pedidos.busqueda.title')}</span>
        </p>
      </div>
      {isBusquedaOpen &&
      <div className='search-by-date__dropdown'>
        <span
          onClick={handleResetDates}
          className='filtros-section__mb-top-btns--borrar search-by-date__dropdown--borrar'>
            <TbTrashXFilled />
            {t('catalogo-piezas.filtros-seccion.borrar-todo')}
         </span>
        <div className='search-by-date__dropdown--date'>
        <p>{t('area-personal.facturas.desde')}</p>
        <input ref={inputRef} max='' type='date' name='fechaDesde' value={searchParams?.fechaDesde} onChange={handleSearchParams} />
        <p>{t('area-personal.facturas.hasta')}</p>
        <input ref={inputRef2} max='' type='date' name='fechaHasta' value={searchParams?.fechaHasta} onChange={handleSearchParams} />
        </div>
        <div onClick={submitSearch} className='search-by-date__dropdown--btn'>
          <button>{isLoading ? <Spinner className='spinner-busqueda-drop-down'/> : t('area-personal.facturas.buscar')}</button>
        </div>
      </div>}
    </section>
  )
}

SearchByDate.propTypes = {
  searchParams: PropTypes.object.isRequired,
  handleSearchParams: PropTypes.func.isRequired,
  handleResetDates: PropTypes.func.isRequired,
  submitSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default SearchByDate
