import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const CheckboxWarehouse = ({ handleOnlyWarehouseParts }) => {
  const { t } = useTranslation()
  return (
    <div className='catalogo-piezas__header--warehouse'>
      <input id='warehouse' type='checkbox' onChange={handleOnlyWarehouseParts} />
      <label htmlFor='warehouse'>{t('catalogo-piezas.from-warehouse')}</label>
    </div>
  )
}

CheckboxWarehouse.propTypes = {
  handleOnlyWarehouseParts: PropTypes.func.isRequired
}

export default CheckboxWarehouse
