import { createBrowserRouter } from 'react-router-dom'
import MainLayout from '../views/MainLayout'
import CatalogoPiezas from '../views/MainLayout/CatalogoPiezas'
import CondicionesCompra from '../views/MainLayout/CondicionesCompra'
import Contacto from '../views/MainLayout/Contacto'
import PiezaDetalle from '../views/MainLayout/PiezaDetalle'
import { Suspense, lazy } from 'react'
import AreaPersonal from '../views/MainLayout/AreaPersonal'
import MisDatos from '../views/MainLayout/AreaPersonal/MisDatos'
import MisDirecciones from '../views/MainLayout/AreaPersonal/MisDirecciones'
import PedidosRealizados from '../views/MainLayout/AreaPersonal/PedidosRealizados'
import Incidencias from '../views/MainLayout/AreaPersonal/Incidencias'
import Facturas from '../views/MainLayout/AreaPersonal/Facturas'
import ResumenCarrito from '../views/MainLayout/ResumenCarrito'
import Favoritos from '../views/MainLayout/AreaPersonal/Favoritos'
import Comercial from '../views/Comercial'
import Register from '../views/Comercial/Register'
import ReenvioEmail from '../views/Comercial/ReenvioEmail'
import Listados from '../views/Comercial/Listados'

const Login = lazy(() => import('../views/Login/index'))
const ResetPassword = lazy(() => import('../views/ResetPassword/index'))

export const router = createBrowserRouter([
  {
    path: '/login',
    element: <Suspense fallback={<div>Loading...</div>}><Login /></Suspense>
  },
  {
    path: '/reset-password/:token',
    element: <Suspense fallback={<div>Loading...</div>}><ResetPassword /></Suspense>
  },
  {
    path: '/comercial',
    element: <Comercial />,
    children: [
      {
        path: '',
        element: <Register />
      },
      {
        path: 'reenvio-email',
        element: <ReenvioEmail />
      },
      {
        path: 'listados',
        element: <Listados />
      }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <CatalogoPiezas />
      },
      {
        path: '/detalle/:piezaId',
        element: <PiezaDetalle />
      },
      {
        path: '/compra',
        element: <CondicionesCompra />
      },
      {
        path: '/contacto',
        element: <Contacto />
      },
      {
        path: '/carrito',
        element: <ResumenCarrito />
      },
      {
        path: '/area-personal',
        element: <AreaPersonal />,
        children: [
          {
            path: '/area-personal/datos',
            element: <MisDatos />
          },
          {
            path: '/area-personal/direcciones',
            element: <MisDirecciones />
          },
          {
            path: '/area-personal/pedidos',
            element: <PedidosRealizados />
          },
          {
            path: '/area-personal/incidencias',
            element: <Incidencias />
          },
          {
            path: '/area-personal/facturas',
            element: <Facturas />
          },
          {
            path: '/area-personal/favoritos',
            element: <Favoritos />
          }
        ]
      },
      {
        path: '*',
        element: <CatalogoPiezas />
      }
    ]
  }
])
