import { useTranslation } from 'react-i18next'
import ChoosePiezaCard from '../ChoosePiezaCard'
import PropTypes from 'prop-types'

const Step0 = ({ pedido, nuevaIncidencia, setStepModal, handlePiezaId }) => {
  const { t } = useTranslation()
  return (
    <>
      <p className='modal-nueva-incidencia__container--subtitle'>{t('area-personal.incidencias.modals.nueva-incidencia.pieza')}</p>
      <div className='modal-nueva-incidencia__container--incidencias container-step0'>
        {pedido?.piezas?.map(pieza =>
          pieza?.incident === 0 ? <ChoosePiezaCard key={pieza?.id} pieza={pieza} handlePiezaId={handlePiezaId} nuevaIncidencia={nuevaIncidencia} /> : ''
        )}
      </div>
      <div className='modal-nueva-incidencia__container--btn-container'>
        <button
          disabled={!nuevaIncidencia?.piezaId}
          onClick={() => setStepModal(1)}>
          {t('area-personal.incidencias.modals.nueva-incidencia.siguiente')}
        </button>
      </div>
    </>
  )
}

Step0.propTypes = {
  pedido: PropTypes.object.isRequired,
  nuevaIncidencia: PropTypes.object.isRequired,
  setStepModal: PropTypes.func.isRequired,
  handlePiezaId: PropTypes.func.isRequired
}

export default Step0
