import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import useScreenWidth from '../../../../hooks/useScreenWidth'
import { AiOutlineSearch } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { getInvoices } from '../../../../services/api'
import FacturasContainerMob from './FacturasContainerMob'
import FacturasContainerDesk from './FacturasContainerDesk'

import './facturas.scss'
import useSearch from '../../../../hooks/useSearch'
import SearchByDate from '../../../shared/SearchByDate'
import FiltroButton from '../../../shared/FiltroButton'

let PageSize = 4

const Facturas = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const screenWidth = useScreenWidth()
  // filter by compras / devoluciones
  const [type, setType] = useState('compras')
  // loading

  const [isLoading, setIsLoading] = useState(true)

  // search

  const handleError = () => {
    return toast.error(t('area-personal.pedidos.busqueda.errorFecha'))
  }

  const { searchParams, handleSearchParams, handleResetDates } = useSearch({ handleError })

  const submitSearch = () => {
    if (isLoading) return

    !hasMoreData && setHasMoreData(true)
    currentPage === 1
      ? reqGetFacturas({ offset: currentPage, limit: PageSize, searchParams, type })
      : setCurrentPage(1)

    setFacturasData([])
  }

  const handleFilterByTipo = (filtro) => {
    !hasMoreData && setHasMoreData(true)
    setType(filtro)
    setCurrentPage(1)

    setFacturasData([])
  }
  // request API

  const reqGetFacturas = async (params) => {
    setIsLoading(true)
    const res = await getInvoices(params)
    if (res?.status === 200) {
      screenWidth < 992 && res.data?.facturas?.length < PageSize && setHasMoreData(false)
      screenWidth >= 992
        ? setFacturasData(res.data?.facturas)
        : currentPage === 1
          ? setFacturasData(res.data?.facturas)
          : setFacturasData((prev) => [...prev, ...res.data?.facturas])
      setTotalFacturasData(res.data?.totalFacturas)
    } else {
      toast.error(t('errors.1'))
    }
    return setIsLoading(false)
  }

  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalFacturasData, setTotalFacturasData] = useState(0)
  const [facturasData, setFacturasData] = useState([])
  const [hasMoreData, setHasMoreData] = useState(true)

  const observerTarget = useRef(null)

  useEffect(() => {
    screenWidth >= 992 ? PageSize = 4 : PageSize = 6
    hasMoreData && reqGetFacturas({ offset: currentPage, limit: PageSize, searchParams, type })
  }, [currentPage, type])

  useEffect(() => {
    if (screenWidth < 992) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setCurrentPage(prev => prev + 1)
          }
        },
        { threshold: 0 }
      )
      if (observerTarget.current) {
        observer.observe(observerTarget.current)
      }
      return () => {
        if (observerTarget.current) {
          observer.unobserve(observerTarget.current)
        }
      }
    }
  }, [observerTarget.current])

  return <div className='facturas'>
        <span
            className='facturas__return'
            onClick={() => navigate('/area-personal')}>
            <BiArrowBack/>
        </span>
        <h2>{t('area-personal.facturas.title')}</h2>
        <section className='facturas__filters'>
            <FiltroButton
               handleFilterBy={handleFilterByTipo}
               option1='compras'
               option2='devoluciones'
               option1Text={t('area-personal.facturas.compras')}
               option2Text={t('area-personal.facturas.devoluciones')}
               />
               <div className='facturas__filters--container'>
                <div className='facturas__filters--container--search'>
                  <input type='text'
                    placeholder={t('area-personal.facturas.buscar')}
                    name='querySearch'
                    value={searchParams.querySearch}
                    onChange={handleSearchParams}
                    onKeyDown={(e) => e.key === 'Enter' && submitSearch()}/>
                  <span onClick={submitSearch}><AiOutlineSearch /></span>
                </div>
                <SearchByDate
                  isLoading={isLoading}
                  searchParams={searchParams}
                  handleSearchParams={handleSearchParams}
                  handleResetDates={handleResetDates}
                  submitSearch={submitSearch} />
              </div>
        </section>
        {screenWidth < 992
          ? <FacturasContainerMob
                isLoading={isLoading}
                facturasData={facturasData}
                observerTarget={observerTarget}/>
          : <FacturasContainerDesk
                isLoading={isLoading}
                facturasData={facturasData}
                currentPage={currentPage}
                totalCount={totalFacturasData}
                pageSize={PageSize}
                onPageChange={setCurrentPage}/>
        }
  </div>
}

export default Facturas
