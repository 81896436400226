import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import box from '../../../images/area-personal/box.png'
// import mensajes from '../../../images/area-personal/mensajes.png'
import logout from '../../../images/area-personal/logout.png'
import { FaClipboardList, FaUserAlt } from 'react-icons/fa'
import { MdLocationOn, MdOutlineFavorite, MdSimCardAlert } from 'react-icons/md'

import './areaPersonal.scss'
import { setCookie } from '../../../services/cookies'
import { Outlet, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import useScreenWidth from '../../../hooks/useScreenWidth'

const AreaPersonal = () => {
  const { t } = useTranslation()
  const user = useSelector(state => state.auth)
  const navigate = useNavigate()
  const [isOpenOutletMob, setIsOpenOutletMob] = useState(false)
  const screenWidth = useScreenWidth()

  const handleLogout = () => {
    setCookie('token_tienda_recomotor', '', -1)
    navigate('/login')
  }

  const handleNavigate = (navLink) => {
    screenWidth < 768 && setIsOpenOutletMob(true)
    navigate(navLink)
  }

  useEffect(() => {
    window.location.pathname === '/area-personal' && setIsOpenOutletMob(false)
    screenWidth >= 768 && window.location.pathname === '/area-personal' && navigate('/area-personal/datos')
    window.scrollTo(0, 0)
  }, [window.location.pathname, screenWidth])

  return <div className='area-personal'>
        <div className={isOpenOutletMob ? 'area-personal__hidden width-100' : ''}>
            <section className='area-personal__header'>
                <h4>{user?.nombre}</h4>
                <p>{t('area-personal.title')}</p>
            </section>
            <section className='area-personal__sections'>
                <div
                    className={
                        window.location.pathname === '/area-personal/datos'
                          ? 'area-personal__sections--path-active'
                          : ''}
                    onClick={() => handleNavigate('/area-personal/datos')}>
                    <span><FaUserAlt/></span><p>{t('area-personal.datos.title')}</p></div>
                <div
                    className={
                        window.location.pathname === '/area-personal/direcciones'
                          ? 'area-personal__sections--path-active'
                          : ''}
                    onClick={() => handleNavigate('/area-personal/direcciones')}>
                    <span className='location-span'><MdLocationOn/></span><p>{t('area-personal.direcciones.title')}</p></div>
                <div
                    className={
                        window.location.pathname === '/area-personal/pedidos'
                          ? 'area-personal__sections--path-active'
                          : ''}
                    onClick={() => handleNavigate('/area-personal/pedidos')}>
                    <span><img className='pedidos-logo' alt='box' src={box}/></span><p>{t('area-personal.pedidos.title')}</p></div>
                <div
                    className={
                        window.location.pathname === '/area-personal/incidencias'
                          ? 'area-personal__sections--path-active'
                          : ''}
                    onClick={() => handleNavigate('/area-personal/incidencias')}>
                    <span className='incidencias-span'><MdSimCardAlert /></span><p>{t('area-personal.incidencias.title')}</p></div>
                <div
                    className={
                        window.location.pathname === '/area-personal/facturas'
                          ? 'area-personal__sections--path-active'
                          : ''}
                    onClick={() => handleNavigate('/area-personal/facturas')}>
                    <span className='facturas-span'><FaClipboardList /></span><p>{t('area-personal.facturas.title')}</p></div>
                <div
                    className={
                        window.location.pathname === '/area-personal/favoritos'
                          ? 'area-personal__sections--path-active'
                          : ''}
                    onClick={() => handleNavigate('/area-personal/favoritos')}>
                    <span className='area-personal__sections--fav-icon'><MdOutlineFavorite/></span><p>{t('area-personal.favoritos.title')}</p></div>
                {/* <div
                    className={
                        window.location.pathname === '/area-personal/mensajes'
                          ? 'area-personal__sections--path-active'
                          : ''}
                    onClick={() => handleNavigate('/area-personal/mensajes')}>
                    <span><img src={mensajes} alt='mensajes' /></span><p>{t('area-personal.mensajes.title')}</p></div> */}
            </section>
            <div className='area-personal__logout'
                onClick={handleLogout}>
                <span><img src={logout} alt='logout'/></span>
                <p>{t('area-personal.logout')}</p>
            </div>
        </div>
        <div className={isOpenOutletMob ? 'area-personal__show' : 'area-personal__hidden'}>
            <Outlet setIsOpenOutletMob={setIsOpenOutletMob}/>
        </div>
    </div>
}

export default AreaPersonal
