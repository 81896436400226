import { Outlet, useNavigate } from 'react-router-dom'
import Navbar from '../shared/Navbar'
import Footer from '../shared/Footer'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCookie } from '../../services/cookies'
import { getUserByToken } from '../../services/api'
import { login } from '../../redux/actions/auth'
import { updateCart } from '../../redux/actions/cart'
import { updateFavorites } from '../../redux/actions/favorites'

const MainLayout = () => {
  const dispatch = useDispatch()
  const [isOkLogged, setIsOkLogged] = useState(false)
  const userLogin = useSelector(state => state.auth)
  const navigate = useNavigate()
  const checkLogin = async () => {
    const res = await getUserByToken()
    if (res?.status === 200) {
      dispatch(login(res.data?.user))
      dispatch(updateCart(res.data?.cart))
      dispatch(updateFavorites(res.data?.favorites))
      setIsOkLogged(true)
    } else {
      setCookie('token_tienda_recomotor', '', -1)
      navigate('/login')
    }
  }

  useEffect(() => {
    if (!userLogin) {
      checkLogin()
    } else {
      setIsOkLogged(true)
    }
  }, [])

  return (<div>
        {isOkLogged && <><Navbar/>
            <Outlet/>
            <Footer/></>}
    </div>)
}

export default MainLayout
