import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import './favoritosCard.scss'
import { removeToFavorites } from '../../../../../redux/actions/favorites'
import { deleteToFavoritesRequest } from '../../../../../services/api'
import useScreenWidth from '../../../../../hooks/useScreenWidth'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import AddToCartBtn from '../../../../shared/AddToCartBtn'
import Image from '../../../../shared/Image'
import NoDataBtns from '../../../../shared/NoDataBtns'
import { checkSusceptibleWearingCasco } from '../../../../../utils'
import { AiFillInfoCircle } from 'react-icons/ai'

const FavoritosCard = ({ pieza }) => {
  const { t } = useTranslation()
  const screenWidth = useScreenWidth()
  const [isLoading, setIsLoading] = useState()
  const [isModalSendEmailOpen, setIsModalSendEmailOpen] = useState(false)
  const [isSusceptibleWearingCasco, setIsSusceptibleWearingCasco] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleDeleteFavoritos = async (e) => {
    e && e.stopPropagation()
    const res = await deleteToFavoritesRequest({ piezaStockId: pieza?.id })
    if (res?.status === 200) {
      return dispatch(removeToFavorites(pieza?.id))
    } else {
      return toast.error(t('errors.favoritos.2'))
    }
  }

  useEffect(() => {
    if (checkSusceptibleWearingCasco(pieza)) return setIsSusceptibleWearingCasco(true)
  }, [])

  return (
    <div className={`favoritos-card ${!isModalSendEmailOpen ? 'favoritos-card-hover-active' : ''} ${pieza?.estado !== 1 ? 'blocked' : ''}`} onClick={() => {
      if (pieza?.estado === 1) navigate(`/detalle/${pieza?.id}`)
    }}>
      <div className='favoritos-card__img'>
        <Image foto={pieza?.imgs?.split(',')[0]} />
      </div>
      <div className='favoritos-card__text-container'>
        <h3 className='favoritos-card__text-container--nombre'>{pieza?.nombre}</h3>
        <p className='favoritos-card__text-container--modelo'>{pieza?.marca} {pieza?.modelo}</p>
        {isSusceptibleWearingCasco && <p className='pieza-card__info--casco favoritos-card__text-container--casco'><span><AiFillInfoCircle/></span> {t('catalogo-piezas.pieza-card.casco')}</p>}
        {screenWidth < 1200 && <p className={`favoritos-card__text-container--estado-stock ${pieza?.estado === 1 ? '' : 'no-stock'}`}>{pieza?.estado === 1 ? t('area-personal.favoritos.stock') : t('area-personal.favoritos.no-stock')}</p>}
      </div>
      {screenWidth >= 1200 && <p className={`favoritos-card__text-container--estado-stock ${pieza?.estado === 1 ? '' : 'no-stock'}`}>{pieza?.estado === 1 ? t('area-personal.favoritos.stock') : t('area-personal.favoritos.no-stock')}</p>}
      <h3 className={`favoritos-card__precio ${pieza?.precio_venta !== 0 ? '' : 'favoritos-card__precio--consultar'}`}>{pieza?.precio_venta !== 0 ? (pieza?.precio_venta).toFixed(2) + '€ ' : (t('catalogo-piezas.pieza-card.consultar'))}</h3>
      {pieza?.precio_venta !== 0
        ? <AddToCartBtn
            isSusceptibleWearingCasco={isSusceptibleWearingCasco}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            classNameBtn='favoritos-card__cart'
            classNameSpinner='spinner-favoritos-card'
            pieza={pieza}
            />
        : <NoDataBtns
            setIsModalSendEmailOpen={setIsModalSendEmailOpen}
            pieza={pieza}
            className='favoritos-card__no-data-btns'
            isNotPrice={true} />
      }
      <p className='favoritos-card__delete' onClick={handleDeleteFavoritos}>✖</p>
    </div>
  )
}

FavoritosCard.propTypes = {
  pieza: PropTypes.object.isRequired
}

export default FavoritosCard
