import { types } from '../types'

export const login = (usuario) => ({
  type: types.login,
  payload: usuario
})

export const updateUser = (usuario) => ({
  type: types.updateUser,
  payload: usuario
})

export const updateAddresses = (addresses) => ({
  type: types.updateAddresses,
  payload: addresses
})
