import { useTranslation } from 'react-i18next'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { AiOutlineSearch } from 'react-icons/ai'

import './incidencias.scss'
import { useEffect, useRef, useState } from 'react'
import useScreenWidth from '../../../../hooks/useScreenWidth'
import IncidenciasContainerDesk from './IncidenciasContainerDesk'
import IncidenciasContainerMob from './IncidenciasContainerMob'
import ModalVerIncidencia from './ModalVerIncidencia'
import { getIncidents } from '../../../../services/api'
import { toast } from 'react-toastify'
import FiltroButton from '../../../shared/FiltroButton'

let PageSize = 4

const Incidencias = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const screenWidth = useScreenWidth()

  // loading

  const [isLoading, setIsLoading] = useState(true)

  // modal ver incidencia
  const [isVerIncidenciaOpen, setIsVerIncidenciaOpen] = useState(false)
  const [incidenciaToShow, setIncidenciaToShow] = useState(null)

  const handleVerIncidenciaModal = (incidencia) => {
    setIsVerIncidenciaOpen(true)
    setIncidenciaToShow(incidencia)
  }

  // estado
  const [estado, setEstado] = useState('encurso')

  const handleFilterByEstado = (filtro) => {
    !hasMoreData && setHasMoreData(true)
    setEstado(filtro)
    setCurrentPage(1)

    setIncidenciasData([])
  }

  // search

  const [searchParams, setSearchParams] = useState('')

  const submitSearch = () => {
    if (isLoading) return

    !hasMoreData && setHasMoreData(true)
    currentPage === 1
      ? reqGetIncidents({ offset: currentPage, limit: PageSize, estado, searchParams })
      : setCurrentPage(1)

    setIncidenciasData([])
  }

  // request API

  const reqGetIncidents = async (params) => {
    setIsLoading(true)
    const res = await getIncidents(params)
    if (res?.status === 200) {
      screenWidth < 992 && res.data?.incidencias?.length < PageSize && setHasMoreData(false)
      screenWidth >= 992
        ? setIncidenciasData(res.data?.incidencias)
        : currentPage === 1
          ? setIncidenciasData(res.data?.incidencias)
          : setIncidenciasData((prev) => [...prev, ...res.data?.incidencias])
      setTotalIncidenciasData(res.data?.totalIncidencias)
    } else {
      toast.error(t('errors.1'))
    }
    return setIsLoading(false)
  }

  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalIncidenciasData, setTotalIncidenciasData] = useState(0)
  const [incidenciasData, setIncidenciasData] = useState([])
  const [hasMoreData, setHasMoreData] = useState(true)
  const observerTarget = useRef(null)

  useEffect(() => {
    screenWidth >= 992 ? PageSize = 4 : PageSize = 7
    hasMoreData && reqGetIncidents({ offset: currentPage, limit: PageSize, estado, searchParams })
  }, [currentPage, estado])

  useEffect(() => {
    if (screenWidth < 992) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setCurrentPage(prev => prev + 1)
          }
        },
        { threshold: 1 }
      )
      if (observerTarget.current) {
        observer.observe(observerTarget.current)
      }
      return () => {
        if (observerTarget.current) {
          observer.unobserve(observerTarget.current)
        }
      }
    }
  }, [observerTarget])

  return <div className='incidencias'>
        <span
            className='incidencias__return'
            onClick={() => navigate(-1)}>
            <BiArrowBack/>
        </span>
        <h2>{t('area-personal.incidencias.title')}</h2>
        <section className='incidencias__filters'>
            <FiltroButton
               handleFilterBy={handleFilterByEstado}
               option1='encurso'
               option2='cerrada'
               option1Text={t('area-personal.incidencias.encurso')}
               option2Text={t('area-personal.incidencias.cerradas')}
               />
            <div className='incidencias__filters--container'>
                <div className='incidencias__filters--container--search'>
                    <input type='text'
                        placeholder={t('area-personal.incidencias.buscar')}
                        value={searchParams}
                        onChange={(e) => setSearchParams(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && submitSearch()}/>
                    <span onClick={submitSearch}><AiOutlineSearch /></span>
                </div>
            </div>
        </section>
        {screenWidth < 992
          ? <IncidenciasContainerMob
                isLoading={isLoading}
                handleVerIncidenciaModal={handleVerIncidenciaModal}
                incidenciasData={incidenciasData}
                observerTarget={observerTarget}/>
          : <IncidenciasContainerDesk
                isLoading={isLoading}
                handleVerIncidenciaModal={handleVerIncidenciaModal}
                incidenciasData={incidenciasData}
                currentPage={currentPage}
                totalCount={totalIncidenciasData}
                pageSize={PageSize}
                onPageChange={setCurrentPage}/>
        }
        {isVerIncidenciaOpen && <ModalVerIncidencia setIsVerIncidenciaOpen={setIsVerIncidenciaOpen} incidenciaData={incidenciaToShow} />}
    </div>
}

export default Incidencias
