import './skeletonDirSeguimiento.scss'
const SkeletonDirSeguimiento = () => {
  return (
    <div className='skeleton-dir-seguimiento'>
      <p className='skeleton-dir-seguimiento__text1 dir-seguimiento-loading-animation'></p>
      <p className='skeleton-dir-seguimiento__text2 dir-seguimiento-loading-animation'></p>
      <p className='skeleton-dir-seguimiento__text3 dir-seguimiento-loading-animation'></p>
      <p className='skeleton-dir-seguimiento__text4 dir-seguimiento-loading-animation'></p>
      <p className='skeleton-dir-seguimiento__text5 dir-seguimiento-loading-animation'></p>
    </div>
  )
}

export default SkeletonDirSeguimiento
