import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import FacturaCard from '../FacturaCard'

import './facturasContainerMob.scss'
import SkeletonFacturaCard from '../SkeletonFacturaCard'

const FacturasContainerMob = ({ facturasData, observerTarget, isLoading }) => {
  const { t } = useTranslation()

  return (
    <section className='facturas-container-mob'>
      <p className='facturas-container-mob__titles'>
        <span>{t('area-personal.facturas.num')}</span>
        <span>{t('area-personal.facturas.pieza')}</span>
      </p>
      <div className='facturas-container-mob__container-cards'>
      {facturasData?.map(factura =>
        <FacturaCard key={factura?.numero} factura={factura} observerTarget={observerTarget} />
      )}
      {isLoading && <><SkeletonFacturaCard/><SkeletonFacturaCard/><SkeletonFacturaCard/><SkeletonFacturaCard/><SkeletonFacturaCard/><SkeletonFacturaCard/><SkeletonFacturaCard/></>}
      {!isLoading && facturasData?.length < 1 && <div className='facturas-container-mob__container-cards--nodata'>{t('area-personal.facturas.nodata')}</div>}
      </div>
      <div ref={observerTarget}></div>
    </section>
  )
}

FacturasContainerMob.propTypes = {
  facturasData: PropTypes.array.isRequired,
  observerTarget: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default FacturasContainerMob
