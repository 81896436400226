import { useEffect, useState } from 'react'
import { CgClose } from 'react-icons/cg'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import './modalNuevaIncidencia.scss'
import { toast } from 'react-toastify'
import { createNewIncident, getIncidentsType } from '../../../../../services/api'
// import ChoosePiezaCard from './ChoosePiezaCard'
import Step0 from './Step0'
import Step1 from './Step1'
import Step2 from './Step2'

const ModalNuevaIncidencia = ({ setIsNuevaIncidenciaOpen, setIsOkMessageOpen, pedido, pedidosData, setPedidosData }) => {
  const [tiposIncidencia, setTiposIncidencia] = useState(null)
  const [delayOpen, setDelayOpen] = useState(false)
  const [isConcidionesChecked, setIsConcidionesChecked] = useState(false)
  const [stepModal, setStepModal] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [nuevaIncidencia, setNuevaIncidencia] = useState({
    tipo: '',
    comentario: '',
    fotos: '',
    piezaId: '',
    proveedor_id: ''
  })
  const [nameFotos, setNameFotos] = useState([])
  const { t } = useTranslation()
  const submitNuevaIncidencia = async () => {
    if (!nuevaIncidencia.comentario) {
      toast.error(t('area-personal.incidencias.modals.nueva-incidencia.error.comentario'))
    } else if (!nuevaIncidencia.fotos) {
      toast.error(t('area-personal.incidencias.modals.nueva-incidencia.error.fotos'))
    } else {
      const formData = new FormData()
      formData.append('tipo', nuevaIncidencia.tipo)
      formData.append('comentario', nuevaIncidencia.comentario)
      formData.append('pedidoId', pedido.id)
      formData.append('piezaId', nuevaIncidencia.piezaId)
      formData.append('proveedor_id', nuevaIncidencia.proveedor_id)

      for (let i = 0; i < nuevaIncidencia.fotos.length; i++) {
        formData.append('fotos', nuevaIncidencia.fotos[i])
      }

      setIsLoading(true)

      // petició back
      const res = await createNewIncident(formData)
      if (res?.status === 200) {
        let newArrPedidos = []
        if (pedido?.piezas.length > 1) {
          const newArrPiezas = pedido?.piezas?.map(pieza => {
            return pieza?.id === nuevaIncidencia.piezaId ? { ...pieza, incident: 1 } : pieza
          })
          newArrPedidos = pedidosData?.map(order => {
            return order?.id === pedido?.id ? { ...order, piezas: newArrPiezas } : order
          })
        } else {
          newArrPedidos = pedidosData?.map(order => {
            return order?.id === pedido?.id ? { ...order, piezas: [{ ...order?.piezas[0], incident: 1 }] } : order
          })
        }
        setPedidosData(newArrPedidos)
        setIsNuevaIncidenciaOpen(false)
        setIsOkMessageOpen(true)
      } else {
        toast.error(t('errors.1'))
      };
      return setIsLoading(false)
    }
  }

  const reqGetTiposIncidencia = async () => {
    const res = await getIncidentsType()
    if (res?.status === 200) {
      setTiposIncidencia(res?.data)
    } else {
      toast.error(t('errors.1'))
    }
  }

  const handlePiezaId = async (e, piezaId, proveedorId) => {
    e.target.checked ? setNuevaIncidencia({ ...nuevaIncidencia, piezaId, proveedor_id: proveedorId }) : setNuevaIncidencia({ ...nuevaIncidencia, piezaId: '', proveedor_id: '' })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setDelayOpen(true)
    reqGetTiposIncidencia()
    if (pedido?.piezas?.length > 1) {
      setStepModal(0)
    } else {
      setNuevaIncidencia({ ...nuevaIncidencia, piezaId: pedido?.piezas[0]?.id, proveedor_id: pedido?.piezas[0]?.proveedor.id })
    }
  }, [])

  useEffect(() => {
    const arrNames = []
    if (nuevaIncidencia.fotos !== '') {
      for (let i = 0; i < nuevaIncidencia.fotos.length; i++) {
        arrNames.push(nuevaIncidencia.fotos[i].name)
      }
    }
    setNameFotos(arrNames)
  }, [nuevaIncidencia.fotos])

  return <><div className={`modal-nueva-incidencia ${delayOpen ? 'modal-nueva-incidencia--open' : 'modal-nueva-incidencia--close'}`}>
        <div className={`modal-nueva-incidencia__container ${stepModal === 1 || stepModal === 0 ? '' : 'container-second-step'} ${stepModal === 0 ? 'container-step0' : ''}`}>
            <CgClose
                className='modal-nueva-incidencia__container--close'
                onClick={() => setIsNuevaIncidenciaOpen(false)} />
            <h3 className='modal-nueva-incidencia__container--title'>{(t('area-personal.incidencias.nueva')).toUpperCase()}</h3>
            {
                stepModal === 0
                  ? <Step0
                      pedido={pedido}
                      nuevaIncidencia={nuevaIncidencia}
                      setStepModal={setStepModal}
                      handlePiezaId={handlePiezaId} />
                  : stepModal === 1
                    ? <Step1 tiposIncidencia={tiposIncidencia}
                        setNuevaIncidencia={setNuevaIncidencia}
                        nuevaIncidencia={nuevaIncidencia}
                        setStepModal={setStepModal} />
                    : <Step2
                        isLoading={isLoading}
                        setNuevaIncidencia={setNuevaIncidencia}
                        nuevaIncidencia={nuevaIncidencia}
                        nameFotos={nameFotos}
                        setIsConcidionesChecked={setIsConcidionesChecked}
                        submitNuevaIncidencia={submitNuevaIncidencia}
                        isConcidionesChecked={isConcidionesChecked} />
            }
        </div>
    </div>
    <div className="modal-nueva-incidencia__overlay" />
    </>
}

ModalNuevaIncidencia.propTypes = {
  setIsNuevaIncidenciaOpen: PropTypes.func.isRequired,
  setIsOkMessageOpen: PropTypes.func.isRequired,
  pedido: PropTypes.object.isRequired,
  pedidosData: PropTypes.array.isRequired,
  setPedidosData: PropTypes.func.isRequired
}

export default ModalNuevaIncidencia
