import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import detallesPedido from '../../../../../images/pedidosRealizados/detallesPedido.png'
import incidenciaPedido from '../../../../../images/pedidosRealizados/incidenciaPedido.png'
import seguimientoPedido from '../../../../../images/pedidosRealizados/seguimientoPedido.png'
import './pedidoCard.scss'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const PedidoCard = ({ pedido, setIsSeguimientoOpen, setIsDetallesPedidoOpen, startNuevaIncidencia, setPedidoToShowInfo }) => {
  const { t } = useTranslation()
  const [isOpenIncidentActive, setIsOpenIncidentActive] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    if (pedido?.piezas?.length > 1) {
      const piecesWithoutIncident = pedido?.piezas?.filter(pieza => pieza?.incident === 0)
      return piecesWithoutIncident?.length > 0 ? setIsOpenIncidentActive(true) : setIsOpenIncidentActive(false)
    }
    pedido?.piezas[0]?.incident === 0 ? setIsOpenIncidentActive(true) : setIsOpenIncidentActive(false)
  }, [pedido.piezas])

  return <div className='pedido-card'>
        <div className='pedido-card__column left-align'>
            <span className='pedido-card__column--id'>{pedido?.id}</span>
            <span className='pedido-card__column--fecha'>{pedido?.fecha}</span>
        </div>
        <div className='pedido-card__column triple-column left-align'>
        {
            pedido?.piezas?.map(pieza => {
              return (
                <div key={pieza?.id} className='triple-column-div'>
                  <span className='pedido-card__column--nombre'>{pieza?.tipo_pieza}</span>
                  <span className='pedido-card__column--modelo'>{pieza?.marca} {pieza?.modelo}</span>
                </div>)
            })
        }
        </div>
        <div className='pedido-card__column'>
            <span className='pedido-card__column--title'>{t('area-personal.pedidos.precio')}</span>
            <span className='pedido-card__column--precio'>{pedido?.total}€</span>
        </div>
        <div className='pedido-card__column'>
            <span className='pedido-card__column--title'>{t('area-personal.pedidos.envio')}</span>
            <span className='pedido-card__column--precio'>{pedido?.total_envio}€</span>
        </div>
        <div className='pedido-card__column doble-column'>
            <span className='pedido-card__column--title'>{t('area-personal.pedidos.total')}</span>
            <span className='pedido-card__column--precio'>{pedido?.total_iva}€</span>
        </div>
        <div className='pedido-card__acciones'>
            <div onClick={() => {
              setIsSeguimientoOpen(true)
              setPedidoToShowInfo(pedido)
            }}>
                <span><img className='envio-img' src={seguimientoPedido} alt='envío'/></span>
                <span>{t('area-personal.pedidos.seguimiento')}</span>
            </div>
            <div onClick={() => {
              setIsDetallesPedidoOpen(true)
              setPedidoToShowInfo(pedido)
            }}>
                <span><img className='detalles-img' src={detallesPedido} alt='detalles' /></span>
                <span>{t('area-personal.pedidos.detalles')}</span>
            </div>
            <div
              className={isOpenIncidentActive ? '' : 'max-incidencias-permitido'}
              onClick={() => {
                isOpenIncidentActive ? startNuevaIncidencia(pedido) : navigate('/area-personal/incidencias')
              }}>
                <span><img className='incidencia-img' src={incidenciaPedido} alt='incidencia' /></span>
                <span>{isOpenIncidentActive ? t('area-personal.pedidos.incidencia') : t('area-personal.pedidos.tope-incidencia')}</span>
            </div>
        </div>
    </div>
}

PedidoCard.propTypes = {
  pedido: PropTypes.object.isRequired,
  setIsSeguimientoOpen: PropTypes.func,
  setIsDetallesPedidoOpen: PropTypes.func,
  setPedidoToShowInfo: PropTypes.func,
  startNuevaIncidencia: PropTypes.func
}

export default PedidoCard
