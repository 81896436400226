import { useTranslation } from 'react-i18next'
import ContactoForm from '../../../shared/ContactoForm'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { CgClose } from 'react-icons/cg'

import './modalSendEmail.scss'

const ModalSendEmail = ({ handleSendEmail, isLoading, formValues, setIsOpenModalSendEmail }) => {
  const [delayOpen, setDelayOpen] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
    setDelayOpen(true)
  }, [])
  return (
    <>
      <div className={`modal-send-email ${delayOpen ? 'modal-send-email--open' : ''}`}>
        <div className='modal-send-email__container'>
          <CgClose
            className='modal-send-email__container--close'
            onClick={(e) => {
              e.stopPropagation()
              setIsOpenModalSendEmail(false)
            }} />
          <h2 className='modal-send-email__container--title'>{t('catalogo-piezas.no-data.modal.title')}</h2>
          <h4 className='modal-send-email__container--subtitle'>{t('catalogo-piezas.no-data.modal.subtitle')}</h4>
          <ContactoForm
            onSubmitContacto={handleSendEmail}
            isLoading={isLoading}
            formValues={formValues} />
        </div>
      </div>
      <div className='modal-send-email__overlay' />
    </>
  )
}

ModalSendEmail.propTypes = {
  handleSendEmail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsOpenModalSendEmail: PropTypes.func.isRequired,
  formValues: PropTypes.any
}

export default ModalSendEmail
