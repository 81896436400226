import './skeletonFacturaCard.scss'

const SkeletonFacturaCard = () => {
  return (
    <div className='factura-card skeleton-factura-card'>
      <div className='factura-card__num-pieza'>
        <div className='factura-card__num-pieza--numero'>
          <span className='factura-card__num-pieza--numero--num skeleton-factura-card__numero factura-card-loading-animation'></span>
          <span className='factura-card__num-pieza--numero--fecha skeleton-factura-card__fecha factura-card-loading-animation'></span>
        </div>
        <div className='factura-card__num-pieza--pieza'>
              <p className='factura-card__num-pieza--pieza--each'>
                <span className='factura-card__num-pieza--pieza--each--nombre skeleton-factura-card__nombre factura-card-loading-animation'></span>
                <span className='skeleton-factura-card__modelo factura-card-loading-animation'></span>
              </p>
        </div>
      </div>
      <div className='factura-card__titles'>
        <span className='skeleton-factura-card__title factura-card-loading-animation'></span>
        <span className='skeleton-factura-card__title factura-card-loading-animation'></span>
        <span className='skeleton-factura-card__title factura-card-loading-animation'></span>
        <span className='skeleton-factura-card__title factura-card-loading-animation'></span>
      </div>
      <div className='factura-card__info-precio'>
        <span className='factura-card__info-precio--presupuesto skeleton-factura-card__presupuesto factura-card-loading-animation'></span>
        <span className='skeleton-factura-card__importe factura-card-loading-animation'></span>
        <span className='skeleton-factura-card__importe factura-card-loading-animation'></span>
        <span className='skeleton-factura-card__importe factura-card-loading-animation'></span>
      </div>
      <div className='factura-card__titles'>
      <span className='skeleton-factura-card__title factura-card-loading-animation'></span>
        <span className='skeleton-factura-card__title factura-card-loading-animation'></span>
      </div>
      <div className='factura-card__envio-container'>
        <span className='factura-card__envio-container--estado skeleton-factura-card__estado factura-card-loading-animation'></span>
        <span className='skeleton-factura-card__pago factura-card-loading-animation'></span>
      </div>
      <div className='factura-card__descargar skeleton-factura-card__descargar'>
        <span className='factura-card__descargar--icon skeleton-factura-card__descargar--icon factura-card-loading-animation'></span>
        <span className='factura-card__descargar--text skeleton-factura-card__descargar--text1 factura-card-loading-animation'></span>
        <span className='factura-card__descargar--text skeleton-factura-card__descargar--text2 factura-card-loading-animation'></span>
      </div>
    </div>
  )
}

export default SkeletonFacturaCard
