import { Outlet, useNavigate } from 'react-router-dom'

import NavComercial from './NavComercial'
import { useEffect, useState } from 'react'
import { checkAuthorizedRegister } from '../../services/api'
import { setCookie } from '../../services/cookies'

const Comercial = () => {
  const navigate = useNavigate()
  const [isAuthorized, setIsAuthorized] = useState(false)
  const checkIsAuthorized = async () => {
    const res = await checkAuthorizedRegister()
    if (res?.status === 200) {
      return setIsAuthorized(true)
    }
    setCookie('token_tienda_recomotor', '', -1)
    navigate('/login')
  }

  useEffect(() => {
    checkIsAuthorized()
  }, [])
  return (
    <>
      {isAuthorized &&
        <div>
          <NavComercial />
          <Outlet/>
        </div>
      }
    </>
  )
}

export default Comercial
