const SkeletonEachFile = () => {
  return (
    <div className='modal-ver-incidencia__container--card--prueba--container--card skeleton-each-file detalle-incidencia-loading-animation'>
        <span className='modal-ver-incidencia__container--card--prueba--container--card--icon'>
        </span>
    </div>
  )
}

export default SkeletonEachFile
