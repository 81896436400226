import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MdCancel } from 'react-icons/md'

import './contactoForm.scss'
import Spinner from '../Spinner'

// eslint-disable-next-line react/prop-types
export default function ContactoForm ({ onSubmitContacto, isLoading, formValues = null }) {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
    clearErrors
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      nombre: '',
      email: '',
      telefono: '',
      mensaje: ''
    }
  })
  const [condiciones, setCondiciones] = useState(false)

  const handleChangeCondiciones = (e) => {
    setCondiciones(e.target.checked)
  }

  const onSubmit = async (data, e) => {
    e.preventDefault()
    onSubmitContacto(data)
    setTelefonoValue('')
    reset()
  }

  useEffect(() => {
    if (formValues) {
      setValue('nombre', formValues?.nombre)
      setValue('email', formValues?.email)
      setValue('telefono', formValues?.telefono)
      setValue('mensaje', formValues?.mensaje)
    }
  }, [])

  const [telefonoValue, setTelefonoValue] = useState('')

  const handleTelefonoChange = (event) => {
    let inputValue = event.target.value
    if (!/^[0-9()+-]*$/.test(inputValue)) {
      inputValue = inputValue.slice(0, -1)
    }
    setTelefonoValue(inputValue)
    if (inputValue.length >= 9) clearErrors('telefono')
  }

  return (
            <form className="contacto-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="contacto-form__each-input">
                    <input
                        type="text"
                        {...register('nombre', {
                          maxLength: {
                            value: 50,
                            message: 'maxLength'
                          },
                          required: 'required'
                        })}
                        maxLength={50}
                        placeholder={t('contacto.form.placeholder.1')}
                        defaultValue={''}
                    />
                    <div className="contacto-form__each-input--error">
                        {errors?.nombre && (
                            <div className="contacto-form__each-input--error--message">
                                <MdCancel />
                                {t(`contacto.form.errors.nombre.${errors.nombre.message}`)}
                            </div>
                        )}
                    </div>
                </div>
                <div className="contacto-form__each-input">
                    <input
                        type="email"
                        placeholder={t('contacto.form.placeholder.2')}
                        {...register('email', {
                          required: 'requerido',
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'formato'
                          }
                        })}
                        defaultValue={''}
                    />
                    <div className="contacto-form__each-input--error">
                        {errors?.email && (
                            <div className="contacto-form__each-input--error--message">
                                <MdCancel />
                                {t(`login.form.errors.email.${errors?.email?.message}`)}
                            </div>
                        )}
                    </div>
                </div>

                <div className="contacto-form__each-input">
                    <input
                        type="text"
                        placeholder={t('contacto.form.placeholder.3')}
                        {...register('telefono', {
                          required: 'required',
                          pattern: { value: /^\S.*[0-9]+$/ },
                          minLength: {
                            value: 9,
                            message: 'minLength'
                          }
                        })}
                        value={telefonoValue}
                        onChange={handleTelefonoChange}
                    />
                    <div className="contacto-form__each-input--error">
                        {errors?.telefono && (
                            <div className="contacto-form__each-input--error--message">
                                <MdCancel />
                                {t(`contacto.form.errors.tlf.${errors.telefono.message}`)}
                            </div>
                        )}
                    </div>
                </div>
                <div className="contacto-form__each-input">
                    <textarea
                        type="text"
                        placeholder={t('contacto.form.placeholder.4')}
                        {...register('mensaje', { required: true })}
                        defaultValue={''}
                    ></textarea>

                    <div className="contacto-form__each-input--error">
                        {errors?.mensaje && (
                            <div className="contacto-form__each-input--error--message">
                                <MdCancel />
                                {t('contacto.form.errors.msg')}
                            </div>
                        )}
                    </div>
                </div>

                    <div className="contacto-form__condiciones">
                        <input
                            type="checkbox"
                            name="condiciones"
                            id="condiciones"
                            className="form-check-input"
                            onChange={handleChangeCondiciones}
                            defaultChecked={false}
                        />
                        <small
                            className="form-check-label"
                            htmlFor="condiciones"
                        >
                            {t('contacto.form.checkbox.1')}{' '}
                            <a href="https://recomotor.com/politica-de-privacidad" target="_blank" rel="noreferrer"
                                className="fw-bold"
                            >
                                {t('contacto.form.checkbox.2')}
                            </a>{' '}
                            {t('contacto.form.checkbox.3')}{' '}
                            <Link
                                to={'/compra'}
                                title="Condiciones generales"
                                className="fw-bold"
                                target='_blank'
                            >
                                {t('contacto.form.checkbox.4')}.
                            </Link>
                        </small>
                    </div>
                <div className="contacto-form__btn">
                    <button
                        type="submit"
                        disabled={condiciones === false}
                    >
                        {isLoading ? <Spinner className='spinner-contact-form'/> : t('contacto.form.button')}
                    </button>
                </div>
            </form>
  )
}
