import { useState } from 'react'

const useSearch = ({ handleError }) => {
  const [searchParams, setSearchParams] = useState({
    querySearch: '',
    fechaDesde: '',
    fechaHasta: ''
  })

  const handleSearchParams = (e) => {
    let fechaDesde
    let fechaHasta

    if (e.target.name === 'fechaDesde') {
      fechaDesde = new Date(e.target.value)
      fechaHasta = new Date(searchParams.fechaHasta)
    } else if (e.target.name === 'fechaHasta') {
      fechaDesde = new Date(searchParams.fechaDesde)
      fechaHasta = new Date(e.target.value)
    }

    if (fechaDesde > fechaHasta) {
      setSearchParams({
        ...searchParams, fechaHasta: ''
      })
      return handleError()
    }

    setSearchParams({
      ...searchParams, [e.target.name]: e.target.value
    })
  }

  const handleResetDates = () => {
    setSearchParams({
      ...searchParams,
      fechaDesde: '',
      fechaHasta: ''
    })
  }

  return { searchParams, handleSearchParams, handleResetDates }
}

export default useSearch
