import { useTranslation } from 'react-i18next'
import './confirm.scss'
import { BsCartCheck } from 'react-icons/bs'
import { GoAlert } from 'react-icons/go'
import { useEffect, useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import { CgClose } from 'react-icons/cg'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'

export const Confirm = ({ text, handleConfirm }) => {
  const { t } = useTranslation()
  const [delayOpen, setDelayOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setDelayOpen(true)
  }, [])

  const confirmAlert = () => {
    if (isLoading) return
    setIsLoading(true)
    handleConfirm(true)
  }

  return (
        <>
            <div className={delayOpen ? 'confirm show' : 'confirm'}>
                <div className="confirm-content">
                    <span className='confirm-content__icon'><GoAlert/></span>
                    <p className='confirm-content__text'>{text}</p>
                </div>
                <div className="confirm-btns">
                    <button className={`confirm-btns__confirm ${isLoading ? 'confirm-loading' : ''}`} onClick={confirmAlert}>{isLoading ? <Spinner className='spinner-pieza-card'/> : t('area-personal.direcciones.eliminar')}</button>
                    <button className='confirm-btns__cancel' onClick={() => handleConfirm(false)}>{t('area-personal.direcciones.descartar')}</button>
                </div>
            </div>
            <div
                className="overlay"
                onClick={() => handleConfirm(false)}
            />
        </>
  )
}

Confirm.propTypes = {
  text: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired
}

export const OkMessage = ({ text, text2 = '', setIsOkMessageOpen, isOkMessage = true, isCartCheck = false }) => {
  const [delayOpen, setDelayOpen] = useState(false)
  const [startToShow, setStartToShow] = useState(false)

  useEffect(() => {
    setDelayOpen(true)
    setTimeout(() => {
      setStartToShow(true)
    }, 10)
  }, [])

  return (
        <>
            <div className={delayOpen ? `confirm ok-message ${text2 ? 'text2' : ''} ${startToShow ? 'show' : ''}` : 'confirm ok-message'}>
                <CgClose
                    className='modal-seguimiento__container--close'
                    onClick={() => setIsOkMessageOpen(false)} />
                <div className="ok-message-content">
                    {isOkMessage
                      ? <span className='ok-message-content__icon'><FaCheck /></span>
                      : <span className='confirm-content__icon'>{isCartCheck ? <BsCartCheck /> : <GoAlert />}</span>}
                    {!text2
                      ? <p className='ok-message-content__text'>{text}</p>
                      : <p className='ok-message-content__text2'>
                        <span>{text}</span>
                        <span>{text2}</span>
                      </p>
                    }
                </div>
            </div>
            <div
                className="overlay"
                onClick={() => setIsOkMessageOpen(false)}
            />
        </>
  )
}

OkMessage.propTypes = {
  text: PropTypes.string.isRequired,
  setIsOkMessageOpen: PropTypes.func.isRequired,
  isOkMessage: PropTypes.bool,
  text2: PropTypes.string,
  isCartCheck: PropTypes.bool
}
