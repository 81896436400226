import { useTranslation } from 'react-i18next'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import './misDatos.scss'
import { useSelector } from 'react-redux'

const MisDatos = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useSelector(state => state.auth)
  return <div className='mis-datos'>
        <span
            className='mis-datos__return'
            onClick={() => navigate('/area-personal')}>
            <BiArrowBack/>
        </span>
        <h2>{t('area-personal.datos.title')}</h2>
        <h3>{t('area-personal.datos.subtitle')}</h3>
        <section className='mis-datos__datos'>
            <div>
                <span>{t('area-personal.datos.nombre')}</span>
                <span className='mis-datos__datos--info'>{user?.nombre}</span>
            </div>
            <div>
                <span>{t('area-personal.datos.cif')}</span>
                <span className='mis-datos__datos--info'>{user?.cif}</span>
            </div>
            <div>
                <span>{t('area-personal.datos.tlf')}</span>
                <span className='mis-datos__datos--info'>{user?.telefono}</span>
            </div>
            <div>
                <span>{t('area-personal.datos.email')}</span>
                <span className='mis-datos__datos--info'>{user?.email}</span>
            </div>
            <div className='mis-datos__datos--direccion'>
                <span>{t('area-personal.datos.direccion')}</span>
                <span className='mis-datos__datos--info'>{user?.direccion_facturacion}</span>
            </div>
            <div>
                <span>{t('area-personal.datos.pais')}</span>
                <span className='mis-datos__datos--info'>{user?.pais_facturacion?.nombre}</span>
            </div>
            <div>
                <span>{t('area-personal.datos.poblacion')}</span>
                <span className='mis-datos__datos--info'>{user?.codigo_postal_facturacion} {user?.poblacion_facturacion}, {user?.area_facturacion}</span>
            </div>
        </section>
    </div>
}

export default MisDatos
