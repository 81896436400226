export const types = {
  login: '[Auth] Login',
  updateUser: '[Auth] Update user data',
  updateAddresses: '[Auth] Update addresses',

  updateCart: '[Cart] Vincular presupuesto usuario',
  resetCartState: '[Cart] Resetear estado carrito',

  updateFavorites: '[Favorites] Actualizar favoritos',
  addToFavorites: '[Favorites] Añadir a favoritos',
  removeToFavorites: '[Favorites] Elimminar de favoritos',

  saveFilters: '[Filters] Guardar filtros',
  resetFilters: '[Filters] Resetear filtros'
}
