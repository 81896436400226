import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { GoAlertFill } from 'react-icons/go'
import CardEachSeguimiento from './CardEachSeguimiento'
import { CgClose } from 'react-icons/cg'
import box from '../../../../../images/area-personal/box.png'

import './modalSeguimiento.scss'
import { useEffect, useState } from 'react'
import useScreenWidth from '../../../../../hooks/useScreenWidth'
import { getTracking } from '../../../../../services/api'
import SkeletonDirSeguimiento from './SkeletonDirSeguimiento'
import SkeletonCardEachSeguimiento from './SkeletonCardEachSeguimiento'
import { toast } from 'react-toastify'

const ModalSeguimiento = ({ setIsSeguimientoOpen, pedido }) => {
  const { t } = useTranslation()
  const screenWidth = useScreenWidth()
  const [delayOpen, setDelayOpen] = useState(false)
  const [infoSeguimiento, setInfoSeguimiento] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const reqGetTraking = async (checkoutId) => {
    setIsLoading(true)
    const res = await getTracking({ checkoutId })
    if (res?.status === 200) {
      setInfoSeguimiento(res.data)
    } else {
      toast.error(t('errors.1'))
    }
    return setIsLoading(false)
  }

  useEffect(() => {
    reqGetTraking(pedido?.checkout_id)
    window.scrollTo(0, 0)
    setDelayOpen(true)
  }, [])

  return <><div className={`modal-seguimiento ${delayOpen ? 'modal-seguimiento--open' : 'modal-seguimiento--close'}`}>
        <div className='modal-seguimiento__container'>
            <CgClose
                className='modal-seguimiento__container--close'
                onClick={() => setIsSeguimientoOpen(false)} />
            <h2>{t('area-personal.pedidos.modals.seguimiento.title')}</h2>
            <h5>{t('area-personal.pedidos.modals.seguimiento.direccion')}</h5>
            {!isLoading
              ? <>
                  <h4>{infoSeguimiento?.direccion_entrega?.nombre}</h4>
                  <h4>{infoSeguimiento?.direccion_entrega?.telefono}</h4>
                  <h4>{infoSeguimiento?.direccion_entrega?.direccion}</h4>
                  <h4>{infoSeguimiento?.direccion_entrega?.codigo_postal} {infoSeguimiento?.direccion_entrega?.poblacion}</h4>
                  <h4>{infoSeguimiento?.direccion_entrega?.area}, {infoSeguimiento?.direccion_entrega?.pais?.nombre}</h4>
                </>
              : <SkeletonDirSeguimiento/>}

            {infoSeguimiento?.envios?.length > 1
              ? <>
                <p className='modal-seguimiento__container--alert'><GoAlertFill/> {t('area-personal.pedidos.modals.seguimiento.warning')}</p>
                <p className='modal-seguimiento__container--paquetes'>{infoSeguimiento?.envios?.length} {t('area-personal.pedidos.modals.seguimiento.paquetes')}</p>
              </>
              : <p className='modal-seguimiento__container--one-paquete'></p>}

            <p className='modal-seguimiento__container--titles'>
                <span>{t('area-personal.pedidos.modals.seguimiento.numEnvio')}</span>
                <span>{t('area-personal.pedidos.modals.seguimiento.contenido')}</span>
                {screenWidth >= 992 &&
                    <>
                        <span className='modal-seguimiento__container--titles--center'>{t('area-personal.pedidos.modals.seguimiento.ref')}</span>
                        <span className='modal-seguimiento__container--titles--center'>{t('area-personal.pedidos.modals.seguimiento.transportado')}</span>
                        <span className='modal-seguimiento__container--titles--center'>{t('area-personal.pedidos.modals.seguimiento.estado')}</span>
                    </>
                }
            </p>
            {!isLoading
              ? <>
                {infoSeguimiento?.envios?.length > 0
                  ? infoSeguimiento?.envios?.map((envio, idx) => {
                    return screenWidth < 1200
                      ? <CardEachSeguimiento key={envio?.codigo_transporte + idx} envio={envio} />
                      : <div key={envio?.codigo_transporte + idx} className='modal-seguimiento__container--desktop'>
                            <span className='modal-seguimiento__container--desktop--icon'>
                                <img src={box} alt='box'/>
                            </span>
                            <CardEachSeguimiento envio={envio} />
                        </div>
                  })
                  : screenWidth < 1200
                    ? <div className='modal-seguimiento__container--no-data'>{t('area-personal.pedidos.modals.seguimiento.no-data')}</div>
                    : <div className='modal-seguimiento__container--desktop'>
                        <span className='modal-seguimiento__container--desktop--icon'>
                          <img src={box} alt='box'/>
                        </span>
                        <div className='modal-seguimiento__container--no-data'>{t('area-personal.pedidos.modals.seguimiento.no-data')}</div>
                      </div>
                }
              </>
              : screenWidth < 1200
                ? <SkeletonCardEachSeguimiento/>
                : <div className='modal-seguimiento__container--desktop'>
                    <span className='modal-seguimiento__container--desktop--icon'>
                        <img src={box} alt='box'/>
                    </span>
                  <SkeletonCardEachSeguimiento />
              </div>}
        </div>
    </div>
    <div className="modal-seguimiento__overlay" />
    </>
}

ModalSeguimiento.propTypes = {
  setIsSeguimientoOpen: PropTypes.func,
  pedido: PropTypes.object
}

export default ModalSeguimiento
