import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'

// Config para localización
import './translations/config'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
        <App />
)
reportWebVitals()
