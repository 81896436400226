import { useTranslation } from 'react-i18next'
import Spinner from '../Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { MdShoppingCart } from 'react-icons/md'
import PropTypes from 'prop-types'
import { addToCartRequest, deleteToFavoritesRequest } from '../../../services/api'
import { toast } from 'react-toastify'
import { updateCart } from '../../../redux/actions/cart'
import { removeToFavorites } from '../../../redux/actions/favorites'

const AddToCartBtn = ({ isLoading, setIsLoading, pieza, classNameBtn = '', classNameDisabled = '', classNameSpinner = '', isSusceptibleWearingCasco }) => {
  const { t } = useTranslation()
  const cart = useSelector(state => state.cart)
  const favoritosArr = useSelector(state => state.favorites)
  const dispatch = useDispatch()

  const handleAddToCart = async (e) => {
    e.stopPropagation()
    if (isLoading) return
    if (pieza.estado !== 1) return
    const isAlreadyAdded = cart?.piezas?.find(el => el?.pieza_integracion_id === pieza?.id)
    if (!isAlreadyAdded) {
      setIsLoading(true)
      const piezaData = {
        pieza_integracion_id: pieza?.id,
        id_referencia_plataforma: pieza?.id_original,
        nombre: pieza?.nombre,
        tipo_pieza_id: pieza?.tipo_pieza_id,
        referencia_pieza: pieza?.referencia,
        precio_original: pieza?.precio_original,
        precio_compra: pieza?.precio_compra,
        precio_venta: pieza?.precio_venta,
        precio_transporte: 0,
        presupuesto_id: cart?.id,
        proveedor_id: pieza?.proveedor_id,
        casco: isSusceptibleWearingCasco,
        marca: pieza?.marca,
        modelo: pieza?.modelo,
        integracion_json: JSON.stringify(pieza),
        tipo_garantia_id: pieza?.tipo_garantia_id
      }
      const res = await addToCartRequest(piezaData)
      if (res?.status === 200) {
        dispatch(updateCart(res.data))
        const isAlreadyAddedToFavoritos = favoritosArr?.find(el => el?.id === pieza?.id)
        if (isAlreadyAddedToFavoritos) deleteToFavorites()
        toast.success(t('success.cart.1'))
      } else if (res?.data?.error === 'Part already reserved') {
        toast.error(t('errors.cart.3'))
      } else {
        toast.error(t('errors.cart.1'))
      }
      return setIsLoading(false)
    }
  }

  const deleteToFavorites = async () => {
    const res = await deleteToFavoritesRequest({ piezaStockId: pieza?.id })
    if (res?.status === 200) {
      return dispatch(removeToFavorites(pieza?.id))
    } else {
      return toast.error(t('errors.favoritos.2'))
    }
  }

  return (
    <p className={`${classNameBtn} ${cart?.piezas?.find(el => el?.pieza_integracion_id === pieza?.id) || isLoading ? classNameDisabled : ''}`} onClick={handleAddToCart}>
      <span className={`${classNameBtn}--icon`}>
      {isLoading ? <Spinner className={classNameSpinner}/> : <MdShoppingCart />}
      </span>
      <span className={`${classNameBtn}--text`}>{t('catalogo-piezas.pieza-card.añadir')}</span>
    </p>
  )
}

AddToCartBtn.propTypes = {
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  pieza: PropTypes.object.isRequired,
  classNameBtn: PropTypes.string,
  classNameSpinner: PropTypes.string,
  classNameDisabled: PropTypes.string,
  isSusceptibleWearingCasco: PropTypes.bool
}

export default AddToCartBtn
