import { useEffect, useRef, useState } from 'react'
import useScreenWidth from '../../../../hooks/useScreenWidth'
import PropTypes from 'prop-types'

import MobCarousel from './MobCarousel'
import DeskCarousel from './DeskCarousel'
import SkeletonDeskCarousel from './SkeletonsCarousel/SkeletonDeskCarousel'
import SkeletonMobCarousel from './SkeletonsCarousel/SkeletonMobCarousel'

const ImgCarousel = ({ fotos = [], isLoading }) => {
  const [widthImg, setWidthImg] = useState()
  const carousel = useRef(null)
  const listEl = useRef(null)
  const screenWidth = useScreenWidth()
  const [idxImg, setIdxImg] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      const li = listEl.current
      if (li) {
        setWidthImg(li.getBoundingClientRect().width)
      }
    }, 2000)
  }, [screenWidth])

  const prevImg = () => {
    carousel.current.scrollBy(-widthImg, 0)
    idxImg !== 0 && setIdxImg(prev => prev - 1)
  }

  const nextImg = () => {
    carousel.current.scrollBy(widthImg, 0)
    idxImg !== fotos?.length - 1 && setIdxImg(prev => prev + 1)
  }

  return (
        <>
            {screenWidth > 992
              ? !isLoading
                  ? <DeskCarousel
                        prevImg={prevImg}
                        carousel={carousel}
                        fotos={fotos}
                        listEl={listEl}
                        idxImg={idxImg}
                        nextImg={nextImg}
                        setIdxImg={setIdxImg}/>
                  : <SkeletonDeskCarousel/>
              : !isLoading
                  ? <MobCarousel
                      fotos={fotos} />
                  : <SkeletonMobCarousel />
            }
        </>
  )
}

ImgCarousel.propTypes = {
  fotos: PropTypes.array,
  isLoading: PropTypes.bool.isRequired
}

export default ImgCarousel
