import { useTranslation } from 'react-i18next'
import './condicionesCompra.scss'
import { useEffect, useRef } from 'react'

const CondicionesCompra = () => {
  const { t } = useTranslation()
  const garantiasRef = useRef(null)
  const motoresRef = useRef(null)
  const cajasCambioRef = useRef(null)
  const electricosRef = useRef(null)
  const condicionesRef = useRef(null)
  const plazosRef = useRef(null)
  const danosRef = useRef(null)
  const reservaRef = useRef(null)

  const scrollToSection = (ref) => {
    if (ref.current) {
      const dims = ref.current.getBoundingClientRect()
      window.scrollTo({ top: dims.top - 100, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
        <div className='condiciones-compra'>
            <div className='condiciones-compra__index'>
                <ul>
                    <li onClick={() => scrollToSection(garantiasRef)}>{t('condiciones-compra.garantias.title')}</li>
                    <ul className='condiciones-compra__index--subindex'>
                        <li onClick={() => scrollToSection(motoresRef)}>{t('condiciones-compra.index.motores')}</li>
                        <li onClick={() => scrollToSection(cajasCambioRef)}>{t('condiciones-compra.index.cajas')}</li>
                        <li onClick={() => scrollToSection(electricosRef)}>{t('condiciones-compra.index.electricos')}</li>
                    </ul>
                    <li onClick={() => scrollToSection(condicionesRef)}>{t('condiciones-compra.condiciones.title')}</li>
                    <li onClick={() => scrollToSection(plazosRef)}>{t('condiciones-compra.plazos.title')}</li>
                    <li onClick={() => scrollToSection(danosRef)}>{t('condiciones-compra.daños.title')}</li>
                    <li onClick={() => scrollToSection(reservaRef)}>{t('condiciones-compra.reserva.title')}</li>
                </ul>
            </div>
            <div className='condiciones-compra__info'>
                <h1>{t('condiciones-compra.title')}</h1>
                <h2 ref={garantiasRef}>{t('condiciones-compra.garantias.title')}</h2>
                <p>{t('condiciones-compra.garantias.text.1')}</p>
                <br />
                <p>
                    {t('condiciones-compra.garantias.text.2')}{' '}
                    <b>{t('condiciones-compra.garantias.text.3')}</b>{' '}
                    {t('condiciones-compra.garantias.text.4')}{' '}
                    <b>{t('condiciones-compra.garantias.text.5')}</b>{' '}
                    {t('condiciones-compra.garantias.text.6')}<br />
                    <br />
                    <b ref={motoresRef}>{t('condiciones-compra.garantias.text.7')}</b>{' '}
                    {t('condiciones-compra.garantias.text.8')}{' '}
                    <b>{t('condiciones-compra.garantias.text.9')}</b> <br />
                    <br />
                    <b ref={cajasCambioRef}>{t('condiciones-compra.garantias.text.10')}</b>{' '}
                    {t('condiciones-compra.garantias.text.11')}{' '}
                    <b>{t('condiciones-compra.garantias.text.12')}</b> <br />
                    <br /> <b ref={electricosRef}>{t('condiciones-compra.garantias.text.13')}</b>
                    {t('condiciones-compra.garantias.text.14')}{' '}
                    <b>{t('condiciones-compra.garantias.text.15')}</b>
                </p>
                <h3 ref={condicionesRef}>{t('condiciones-compra.condiciones.title')}</h3>
                <p>
                    {t('condiciones-compra.condiciones.text.1')}
                </p>
                <br />
                <p>
                    {t('condiciones-compra.condiciones.text.2')}
                </p>

                <h3 ref={plazosRef}>{t('condiciones-compra.plazos.title')}</h3>
                <p>
                    {t('condiciones-compra.plazos.text')}
                </p>

                <h3 ref={danosRef}>{t('condiciones-compra.daños.title')}</h3>
                <p>
                    {t('condiciones-compra.daños.text')}
                </p>

                <h3 ref={reservaRef}>{t('condiciones-compra.reserva.title')}</h3>
                <p>
                    {t('condiciones-compra.reserva.text.1')}{' '}
                    <b>{t('condiciones-compra.reserva.text.2')}</b>{' '}
                    {t('condiciones-compra.reserva.text.3')}{' '}
                    <b>{t('condiciones-compra.reserva.text.4')}</b>{' '}
                    {t('condiciones-compra.reserva.text.5')}
                </p>

                <br />
                <p>
                    <b>
                        {t('condiciones-compra.reserva.text.6')}
                    </b>
                </p>
            </div>
        </div>
  )
}

export default CondicionesCompra
