import PropTypes from 'prop-types'
import Pagination from '../../../../shared/Pagination'
import { useTranslation } from 'react-i18next'
import IncidenciaCard from '../IncidenciaCard'

import './incidenciasContainerDesk.scss'
import SkeletonIncidenciaCard from '../SkeletonIncidenciaCard'

const IncidenciasContainerDesk = ({ incidenciasData, currentPage, totalCount, pageSize, onPageChange, handleVerIncidenciaModal, isLoading }) => {
  const { t } = useTranslation()

  return <section className='incidencias-container-desk'>
        <p className='incidencias-container-desk__header'>
            <span>{t('area-personal.incidencias.num')}</span>
            <span>{t('area-personal.incidencias.pieza')}</span>
            <span className='incidencias-container-desk__header--center'>{t('area-personal.incidencias.tipo')}</span>
            <span className='incidencias-container-desk__header--center'>{t('area-personal.incidencias.factura')}</span>
            <span className='incidencias-container-desk__header--center'>{t('area-personal.incidencias.estado')}</span>
            <span className='incidencias-container-desk__header--center'>{t('area-personal.incidencias.acciones')}</span>
        </p>
        <div className='incidencias-container-desk__container-cards'>
        {!isLoading
          ? <>
            {incidenciasData?.map(incidencia =>
                <IncidenciaCard key={incidencia?.numero} incidencia={incidencia} handleVerIncidenciaModal={handleVerIncidenciaModal} />)}
            {incidenciasData?.length < 1 && <div className='facturas-container-desk__container-cards--nodata'>{t('area-personal.incidencias.nodata')}</div>}
            </>
          : <><SkeletonIncidenciaCard/><SkeletonIncidenciaCard/><SkeletonIncidenciaCard/><SkeletonIncidenciaCard/></>}
        </div>
        {incidenciasData?.length > 0 && <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageChange={onPageChange}
        />}
    </section>
}

IncidenciasContainerDesk.propTypes = {
  incidenciasData: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  handleVerIncidenciaModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default IncidenciasContainerDesk
