import { BiArrowBack } from 'react-icons/bi'
import { MdAdd } from 'react-icons/md'
import { AiFillInfoCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import EachDirCard from './EachDirCard'
import FormDirEnvio from '../../../shared/FormDirEnvio'
import { useEffect, useRef, useState } from 'react'

import './misDirecciones.scss'
import { Confirm } from '../../../shared/Alert'
import { deleteAddress, getAddresses } from '../../../../services/api'
import { useDispatch, useSelector } from 'react-redux'
import { updateAddresses, updateUser } from '../../../../redux/actions/auth'
import SkeletonDirCard from './SkeletonDirCard'
import { toast } from 'react-toastify'

const MisDirecciones = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth)
  const formRef = useRef(null)
  const [dirEnvio, setDirEnvio] = useState('otra')
  const [dirToDelete, setDirToDelete] = useState(null)
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [direcciones, setDirecciones] = useState(null)

  // loading

  const [isLoading, setIsLoading] = useState(true)

  const handleConfirmDelete = async (result) => {
    if (result) {
      const res = await handleDelete(dirToDelete)
      if (res) {
        toast.success(t('success.addresses.1'))
      } else {
        toast.error(t('errors.addresses.1'))
      }
    }
    setOpenConfirmDelete(false)
  }

  const handleStartDelete = (direccionId) => {
    setDirToDelete(direccionId)
    setOpenConfirmDelete(true)
    window.scrollTo(0, 0)
  }

  const handleDelete = async (direccionId) => {
    const res = await deleteAddress({ addressId: direccionId })
    if (res?.status === 200) {
      const newArrDir = direcciones?.dirEnvio?.filter(dir => dir.id !== direccionId)
      dispatch(updateAddresses(newArrDir))
      setDirecciones({ ...direcciones, dirEnvio: newArrDir })
      return true
    } else {
      return false
    }
  }

  const handleOpenForm = (direccion) => {
    setDirEnvio(direccion)
    setIsOpenForm(true)
    direcciones?.dirEnvio?.length > 0 && formRef.current.lastElementChild.scrollIntoView({ behavior: 'smooth' })
  }

  const addNewDirection = async (direccion) => {
    dispatch(updateAddresses([...direcciones.dirEnvio, direccion]))
    setDirecciones({ ...direcciones, dirEnvio: [...direcciones.dirEnvio, direccion] })
    setIsOpenForm(false)
    window.scrollTo(0, 0)
  }

  const reqGetAddresses = async () => {
    setIsLoading(true)
    const res = await getAddresses()
    if (res?.status === 200) {
      dispatch(updateUser(res?.data?.user))
      setDirecciones({
        dirEnvio: res?.data?.user?.direcciones,
        dirFacturacion: {
          nombre: res?.data?.user?.nombre,
          cif: res?.data?.user?.cif,
          direccion: res?.data?.user?.direccion_facturacion,
          codigo_postal: res?.data?.user?.codigo_postal_facturacion,
          poblacion: res?.data?.user?.poblacion_facturacion,
          area: res?.data?.user?.area_facturacion,
          pais: res?.data?.user?.pais_facturacion
        }
      })
    } else {
      toast.error(t('errors.1'))
    }
    return setIsLoading(false)
  }

  const isAddressOutOfRange = (address) => {
    if ((address?.poblacion?.codigo_postal >= '51001' && address?.poblacion?.codigo_postal <= '51080') || // CEUTA
    (address?.poblacion?.codigo_postal >= '52001' && address?.poblacion?.codigo_postal <= '52080') || // MELILLA
    ((address?.poblacion?.codigo_postal >= '35001' && address?.poblacion?.codigo_postal <= '35660') || (address?.poblacion?.codigo_postal >= '38001' && address?.poblacion?.codigo_postal <= '38917')) || // CANARIAS
    ((address?.poblacion?.codigo_postal >= '9000-001' && address?.poblacion?.codigo_postal <= '9980-999')) // ISLAS PORTUGAL
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    reqGetAddresses()
  }, [])

  return <div className='mis-direcciones'>
        <span
            className='mis-direcciones__return'
            onClick={() => navigate('/area-personal')}>
            <BiArrowBack/>
        </span>
        <h2>{t('area-personal.direcciones.envio.title')}</h2>
        {<div className='mis-direcciones__direcciones'>
            <div ref={formRef}>
                {!isLoading
                  ? <>
                    {direcciones?.dirEnvio?.map((dir, idx) => (
                      <EachDirCard key={idx}
                        direccion={dir}
                        handleDelete={handleStartDelete}
                        isOutOfRange={isAddressOutOfRange(dir)}
                        />))}</>
                  : <div className='mis-direcciones__container-skeleton'>{user?.direcciones?.map(direccion => <SkeletonDirCard key={direccion.id} isBtn={true} />)}</div>}
            </div>
            <button
                className='mis-direcciones__direcciones--add'
                onClick={() => handleOpenForm('otra')}><span><MdAdd/></span>{t('area-personal.direcciones.añadir')}</button>
        </div>}
        {isOpenForm &&
            <FormDirEnvio
            dirEnvio={dirEnvio}
            className={'mis-direcciones__form'}
            addNewDirection={addNewDirection}
            />}
        <span className='mis-direcciones__separador'></span>
        <h2 className='mis-direcciones__title-facturacion'>{t('area-personal.direcciones.facturacion.title')}</h2>
        {!isLoading
          ? <>
            {direcciones && <EachDirCard
              direccion={direcciones?.dirFacturacion}
              isDirEnvio={false}/>}
            </>
          : <SkeletonDirCard />}
        <p className='mis-direcciones__info mis-direcciones__info--padding'><span><AiFillInfoCircle/></span> {t('area-personal.direcciones.facturacion.cambiar')}</p>
        {openConfirmDelete && <Confirm
          text={t('area-personal.direcciones.confirm')}
          handleConfirm={handleConfirmDelete} />}
    </div>
}

export default MisDirecciones
