import { useEffect, useState } from 'react'
import { HiOutlineMenu } from 'react-icons/hi'
import { IoMdClose } from 'react-icons/io'
import { FaUserAlt } from 'react-icons/fa'
import { MdShoppingCart } from 'react-icons/md'
import logo2 from '../../../images/login/logo2.png'
import { Link, useNavigate } from 'react-router-dom'

import './navbar.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { resetFilters } from '../../../redux/actions/filters'
import { checkAuthorizedRegister } from '../../../services/api'
import LanguageBtn from './LanguageBtn'
import { LuFileCog } from 'react-icons/lu'

const Navbar = () => {
  const { t } = useTranslation()
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [pathname, setPathname] = useState('/')
  const [isAuthorized, setIsAuthorized] = useState(false)
  const cart = useSelector(state => state.cart)
  const user = useSelector(state => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const filters = useSelector(state => state.filters)

  const checkIsAuthorized = async () => {
    const res = await checkAuthorizedRegister()
    if (res?.status === 200) {
      return setIsAuthorized(true)
    }
  }

  const handleChangePath = (newPathName) => {
    setPathname(newPathName)
    setIsNavOpen(false)
  }

  const handleResetFilters = (pathname) => {
    if (['/carrito', '/contacto', '/compra'].includes(pathname)) return true
    if (pathname.includes('/area-personal')) return true
    return false
  }

  useEffect(() => {
    handleChangePath(window.location.pathname)
    if (filters && handleResetFilters(window.location.pathname)) {
      dispatch(resetFilters())
    }
  }, [window.location.pathname])

  useEffect(() => {
    checkIsAuthorized()
  }, [])

  return (
        <nav className='navbar'>
            <Link to='/' className="navbar__logo">
                <img src={logo2} alt='Recomotor' />
            </Link>
            <button
                className="navbar__hamburger"
                onClick={() => {
                  setIsNavOpen(!isNavOpen)
                }}
            >
                { isNavOpen ? <IoMdClose/> : <HiOutlineMenu/>}
            </button>
            <div
                className={
                    isNavOpen
                      ? 'navbar__menu navbar__menu--expanded'
                      : 'navbar__menu'
                }
            >
                <ul>
                    <li className={pathname === '/' ? 'active' : ''}>
                        <Link to="/">{t('navbar.piezas')}</Link>
                    </li>
                    <li className={pathname === '/compra' ? 'active' : ''}>
                        <Link to="/compra">{t('navbar.compra')}</Link>
                    </li>
                    <li className={pathname === '/contacto' ? 'active' : ''}>
                        <Link to="/contacto">{t('navbar.contacto')}</Link>
                    </li>
                    <li>
                      <LanguageBtn />
                    </li>
                </ul>
                {isAuthorized &&
                  <div
                    className='navbar__menu--comercial navbar__menu--cart navbar__menu--cart--expanded'
                    onClick={() => navigate('/comercial')}
                  >
                    <span className='navbar__menu--cart--icon'>
                    <LuFileCog />
                  </span></div>
                }
                <div className='navbar__menu--cart navbar__menu--cart--expanded' onClick={() => navigate('/carrito')}>
                    <span className='navbar__menu--cart--icon'>
                        <MdShoppingCart/>
                    </span>
                    <div className='navbar__menu--cart--number'>{cart?.piezas?.length}</div>
                </div>
                <div className='navbar__menu--user' onClick={() => navigate('/area-personal')}>
                    <span className='navbar__menu--user--icon'>
                        <FaUserAlt/>
                    </span>
                    <div className='navbar__menu--user--desk'>
                        <p className='navbar__menu--user--desk--name'>{user?.nombre}</p>
                        <p className='navbar__menu--user--desk--area'>{t('navbar.area')} &gt;</p>
                    </div>
                </div>
            </div>
        </nav>
  )
}

export default Navbar
