import '../skeletonsCarousel.scss'

const SkeletonMobCarousel = () => {
  return (
    <div className='skeleton-mob-carousel'>
      <div className='skeleton-mob-carousel__container'>
        <span className='skeleton-mob-carousel__container--img carousel-loading-animation'></span>
      </div>
    </div>
  )
}

export default SkeletonMobCarousel
