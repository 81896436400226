import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useScreenWidth from '../../../../hooks/useScreenWidth'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteToCartRequest } from '../../../../services/api'
import Image from '../../Image'
import { updateCart } from '../../../../redux/actions/cart'
import { toast } from 'react-toastify'
import { checkSusceptibleWearingCasco } from '../../../../utils'
import { AiFillInfoCircle } from 'react-icons/ai'
import Spinner from '../../Spinner'
import { TbTrashXFilled } from 'react-icons/tb'

const EachCardDetalle = ({ pieza, isCasco = false, className, isPartsSusceptibleWearingCasco, piezasStatusChecked, setPiezasStatusChecked }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const screenWidth = useScreenWidth()
  const cart = useSelector(state => state.cart)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const handleRemoveToCart = async (e, pieza) => {
    e.stopPropagation()
    if (isLoading) return
    setIsLoading(pieza?.id)
    const res = await deleteToCartRequest({ pieza_id: pieza?.id, presupuesto_id: cart?.id, pieza_integracion_id: pieza?.pieza_integracion_id, part_status: pieza?.estado })
    if (res?.status === 200) {
      dispatch(updateCart(res?.data))
      const newArrParts = piezasStatusChecked.filter(part => part?.id !== pieza?.id)
      setPiezasStatusChecked(newArrParts)
    } else {
      toast.error(t('errors.1'))
    }
    return setIsLoading(false)
  }

  return (
    <>
    <div
        onClick={() => {
          if (pieza.estado !== 0 && pieza.estado !== 2) {
            navigate(`/detalle/${pieza?.pieza_integracion_id}`)
          }
        }}
        className={`detalle-carrito__pieza ${className} ${(pieza?.estado !== 1 && pieza?.estado !== 3) ? 'blocked' : ''}`}>
        <div className='detalle-carrito__pieza--foto'>
            <span>
                <Image foto={(pieza?.imgs === 'object' && pieza?.imgs?.length >= 0) ? '' : (pieza?.imgs?.split(',')[0] || '')} />
            </span>
        </div>
        <div className='detalle-carrito__pieza--info'>
            <h4>{pieza?.nombre}</h4>
            <p className='detalle-carrito__pieza--info--subtitle'>{pieza?.marca?.nombre} {pieza?.modelo?.nombre}</p>
            {
              checkSusceptibleWearingCasco({ notapublica: pieza?.observaciones || '', tipo_pieza_id: pieza?.tipo_pieza?.id }) &&
              <p className='pieza-card__info--casco info-detalle__container--casco'><span><AiFillInfoCircle/></span> {t('catalogo-piezas.pieza-card.casco')}</p>}
            {screenWidth > 992 &&
                <>
                    <p className='detalle-carrito__pieza--info--labels'>
                        <span>OEM: <b>{pieza?.referencia_pieza}</b></span>
                        <span>ID: <b>{pieza?.pieza_integracion_id}</b></span>
                    </p>
                    <p className='detalle-carrito__pieza--info--observaciones'>{t('catalogo-piezas.pieza-card.observaciones')} {pieza?.notapublica || pieza?.observaciones || '-'}</p>
                </>
            }
        </div>
        <h3 className='detalle-carrito__pieza--precio'>{(pieza?.estado !== 1 && pieza?.estado !== 3) && <span>{t('area-personal.favoritos.no-stock')}</span>} {(pieza?.precio_venta).toFixed(2)} €</h3>
        <span className={`detalle-carrito__pieza--eliminar ${isLoading ? 'disabled-to-delete' : ''}`} onClick={(e) => handleRemoveToCart(e, pieza)}>{isLoading === pieza?.id ? <Spinner className='spinner-eliminar-pieza'/> : <TbTrashXFilled />}</span>
    </div>
    {isCasco && <div
        onClick={() => navigate(`/detalle/${pieza?.pieza_integracion_id}`)}
        className={`detalle-carrito__pieza ${className}`}>
        <div className='detalle-carrito__pieza--foto'>
            <span>
                <Image foto={(pieza?.imgs === 'object' && pieza?.imgs?.length >= 0) ? '' : (pieza?.imgs?.split(',')[0] || '')} />
            </span>
        </div>
        <div className='detalle-carrito__pieza--info'>
            <h4>{isCasco && 'CASCO '}{pieza?.nombre}</h4>
            <p className='detalle-carrito__pieza--info--subtitle'>{pieza?.marca?.nombre} {pieza?.modelo?.nombre}</p>
            {screenWidth > 992 &&
                    <p className='detalle-carrito__pieza--info--labels'>
                        <span>OEM: <b>{pieza?.referencia_pieza}</b></span>
                        <span>ID: <b>{pieza?.pieza_integracion_id}</b></span>
                    </p>
            }
        </div>
        <h3 className='detalle-carrito__pieza--precio'>{(pieza?.estado !== 1 && pieza?.estado !== 3) && <span>{t('area-personal.favoritos.no-stock')}</span>} {(pieza?.casco || 0).toFixed(2)} €</h3>
    </div>}
    </>
  )
}

EachCardDetalle.propTypes = {
  pieza: PropTypes.object.isRequired,
  isCasco: PropTypes.bool,
  className: PropTypes.string,
  isPartsSusceptibleWearingCasco: PropTypes.bool.isRequired,
  piezasStatusChecked: PropTypes.array.isRequired,
  setPiezasStatusChecked: PropTypes.any.isRequired
}

export default EachCardDetalle
