import './skeletonIncidenciaCard.scss'
const SkeletonIncidenciaCard = () => {
  return (
    <div className='skeleton-incidencia-card incidencia-card'>
        <div className='incidencia-card__each-container'>
            <span className='skeleton-incidencia-card__numero incidencia-card-loading-animation'></span>
            <span className='skeleton-incidencia-card__fecha incidencia-card-loading-animation'></span>
        </div>
            <div className='incidencia-card__each-container doble-fr-grid'>
                <span className='skeleton-incidencia-card__nombre incidencia-card-loading-animation'></span>
                <span className='skeleton-incidencia-card__modelo incidencia-card-loading-animation'></span>
            </div>
        <div className='incidencia-card__each-container align-text-center'>
            <span className='skeleton-incidencia-card__title incidencia-card-loading-animation'></span>
            <span className='skeleton-incidencia-card__tipo incidencia-card-loading-animation'></span>
        </div>
        <div className='incidencia-card__each-container align-text-center'>
            <span className='skeleton-incidencia-card__title incidencia-card-loading-animation'></span>
            <span className='skeleton-incidencia-card__factura incidencia-card-loading-animation'></span>
        </div>
        <div className='incidencia-card__each-container doble-padding-bottom align-text-center'>
            <span className='skeleton-incidencia-card__title incidencia-card-loading-animation'></span>
            <span className='skeleton-incidencia-card__estado incidencia-card-loading-animation'></span>
        </div>
        <div className='incidencia-card__container-ver skeleton-incidencia-card__container-ver'>
            <span className='incidencia-card__container-ver--icon skeleton-incidencia-card__icon incidencia-card-loading-animation' ></span>
            <span className='skeleton-incidencia-card__text1 incidencia-card-loading-animation'></span>
            <span className='skeleton-incidencia-card__text2 incidencia-card-loading-animation'></span>
        </div>
    </div>
  )
}

export default SkeletonIncidenciaCard
