import './contacto.scss'
import { MdOutlinePhone, MdOutlineEmail } from 'react-icons/md'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { sendContact } from '../../../services/api'
import { useEffect, useState } from 'react'
import ContactoForm from '../../shared/ContactoForm'

export default function Contacto () {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const handleSendContacto = async (data) => {
    setIsLoading(true)
    const res = await sendContact(data)
    if (res?.status === 200) {
      toast.success(t('success.contact.1'))
    } else {
      toast.error(t('errors.1'))
    }
    setIsLoading(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
        <div className="contacto">
            <div className="contacto__info">
                <h1 className="contacto__info--title">{t('contacto.title')}</h1>
                <p className='contacto__info--subtitle'>
                    {t('contacto.subtitle.1')} <br />{' '}
                    {t('contacto.subtitle.2')}
                </p>

                <div className="contacto__info--contact-container">
                    <div className="contacto__info--contact-container__contact">
                        <span className="contacto__info--contact-container__contact--icon">
                            <MdOutlineEmail />
                        </span>

                        <a
                            href="mailto:info@recomotor.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            info@recomotor.com
                        </a>
                    </div>

                    <div className="contacto__info--contact-container__contact">
                        <span className="contacto__info--contact-container__contact--icon">
                            <MdOutlinePhone />
                        </span>
                        <a
                            href="tel:+34973939392"
                            target="_blank"
                            rel="noreferrer"
                        >
                            +34 973 93 93 92
                        </a>
                    </div>
                </div>
            </div>

            <div className="contacto__form">
                <ContactoForm onSubmitContacto={handleSendContacto} isLoading={isLoading} />
            </div>
        </div>
  )
}
