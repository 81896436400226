import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { BiSolidFileBlank } from 'react-icons/bi'
import Spinner from '../../../../../shared/Spinner'
import { Link } from 'react-router-dom'

const Step2 = ({ setNuevaIncidencia, nuevaIncidencia, nameFotos, setIsConcidionesChecked, submitNuevaIncidencia, isConcidionesChecked, isLoading }) => {
  const { t } = useTranslation()
  return (
    <>
      <p className='modal-nueva-incidencia__container--subtitle'>{t('area-personal.incidencias.modals.nueva-incidencia.describenos')}</p>
      <textarea
        onChange={(e) => setNuevaIncidencia({ ...nuevaIncidencia, comentario: e.target.value })}
        value={nuevaIncidencia?.comentario}
        className='modal-nueva-incidencia__container--comentarios'
        placeholder={t('area-personal.incidencias.modals.nueva-incidencia.comentarios')}/>
        <p className='modal-nueva-incidencia__container--prueba-grafica'>{t('area-personal.incidencias.modals.nueva-incidencia.incluya')}</p>
        <div className='modal-nueva-incidencia__container--fotos'>
          <span>{t('area-personal.incidencias.modals.nueva-incidencia.subir')}</span>
          <label>
            {t('area-personal.incidencias.modals.nueva-incidencia.seleccionar')}
              <input type='file' multiple onChange={(e) => setNuevaIncidencia({ ...nuevaIncidencia, fotos: e.target.files })}/>
          </label>
        </div>
        <div className='modal-nueva-incidencia__container--fotos-names'>
          {nameFotos?.length > 0 && <>
            <p className='modal-nueva-incidencia__container--fotos-names--title'>{t('area-personal.incidencias.modals.nueva-incidencia.archivos')}</p>
            <p className='modal-nueva-incidencia__container--fotos-names--archivos'>{nameFotos?.map(name => <span key={name}><BiSolidFileBlank/> {name}</span>)}</p>
          </>}
        </div>
        <div className='modal-nueva-incidencia__container--condiciones'>
          <input type='checkbox' onChange={(e) => setIsConcidionesChecked(e.target.checked)}/>
          <p>
            {t('area-personal.incidencias.modals.nueva-incidencia.condiciones.1')}
            <a href="https://recomotor.com/politica-de-privacidad" target="_blank" rel="noreferrer"> {t('area-personal.incidencias.modals.nueva-incidencia.condiciones.2')} </a>
            {t('area-personal.incidencias.modals.nueva-incidencia.condiciones.3')}
            <Link to={'/compra'} target='_blank'> {t('area-personal.incidencias.modals.nueva-incidencia.condiciones.4')}</Link>
          </p>
        </div>
        <div className='modal-nueva-incidencia__container--btn-container'>
          <button
            onClick={submitNuevaIncidencia}
            disabled={!isConcidionesChecked}>
              {isLoading ? <Spinner className='spinner-nueva-incidencia'/> : t('area-personal.incidencias.modals.nueva-incidencia.enviar')}
            </button>
        </div>
    </>
  )
}

Step2.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setNuevaIncidencia: PropTypes.func.isRequired,
  nuevaIncidencia: PropTypes.object.isRequired,
  nameFotos: PropTypes.array.isRequired,
  setIsConcidionesChecked: PropTypes.func.isRequired,
  isConcidionesChecked: PropTypes.bool.isRequired,
  submitNuevaIncidencia: PropTypes.func.isRequired
}

export default Step2
