import { useTranslation } from 'react-i18next'
import incidenciaPedido from '../../../../../images/pedidosRealizados/incidenciaPedido.png'
import PropTypes from 'prop-types'

import './incidenciaCard.scss'

const IncidenciaCard = ({ incidencia, handleVerIncidenciaModal }) => {
  const { t } = useTranslation()

  return <div className='incidencia-card'>
        <div className='incidencia-card__each-container'>
            <span className='incidencia-card__each-container--numero'>{incidencia?.numero}</span>
            <span className='incidencia-card__each-container--fecha'>{incidencia?.fecha}</span>
        </div>
            <div className='incidencia-card__each-container doble-fr-grid'>
                <span className='incidencia-card__each-container--nombre'>{incidencia?.pieza[0]?.tipo_pieza}</span>
                <span className='incidencia-card__each-container--modelo'>{incidencia?.pieza[0]?.marca} {incidencia?.pieza[0]?.modelo}</span>
            </div>
        <div className='incidencia-card__each-container align-text-center'>
            <span className='incidencia-card__each-container--title'>{t('area-personal.incidencias.tipo')}</span>
            <span className='incidencia-card__each-container--tipo'>{incidencia?.tipo_incidencia}</span>
        </div>
        <div className='incidencia-card__each-container align-text-center'>
            <span className='incidencia-card__each-container--title'>{t('area-personal.incidencias.factura')}</span>
            <span className='incidencia-card__each-container--factura'>{incidencia?.venta}</span>
        </div>
        <div className='incidencia-card__each-container doble-padding-bottom align-text-center'>
            <span className='incidencia-card__each-container--title'>{t('area-personal.incidencias.estado')}</span>
            <span className='incidencia-card__each-container--estado'>{(incidencia?.estado?.nombre.toUpperCase())}</span>
        </div>
        <div className='incidencia-card__container-ver'>
            <span className='incidencia-card__container-ver--icon' onClick={() => handleVerIncidenciaModal(incidencia)}><img src={incidenciaPedido} alt='incidencia'/></span>
            <span className='incidencia-card__container-ver--text' onClick={() => handleVerIncidenciaModal(incidencia)}>{t('area-personal.incidencias.ver')}</span>
        </div>
    </div>
}

IncidenciaCard.propTypes = {
  incidencia: PropTypes.object.isRequired,
  handleVerIncidenciaModal: PropTypes.func.isRequired
}

export default IncidenciaCard
