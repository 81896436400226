import API from './apiConfig'

// AUTH

export const loginRequest = async (formData) => {
  return await API
    .post('/auth/login', formData)
    .catch(error => error.response)
}

export const getUserByToken = async () => {
  return await API
    .get('/users/me')
    .catch(error => error.response)
}

export const resetPassword = async (email) => {
  return await API
    .post('/auth/resetpassword', { email })
    .catch(error => error.response)
}

export const restartPassword = async (formData) => {
  return await API
    .post('/auth/restartpassword', formData)
    .catch(error => error.response)
}

export const checkAuthorizedRegister = async () => {
  return await API
    .get('/users/checkauthorizedregister')
    .catch(error => error.response)
}

// COMERCIAL

export const createNewUser = async (formData) => {
  return await API
    .post('/auth/createuser', formData)
    .catch(error => error.response)
}

export const getInfoUser = async (searchParams) => {
  return await API
    .post('/users/getinfouser', { searchParams })
    .catch(error => error.response)
}

export const editEmail = async (userData) => {
  return await API
    .post('/auth/editemail', userData)
    .catch(error => error.response)
}

export const forwardingEmail = async (userData) => {
  return await API
    .post('/auth/emailforwarding', userData)
    .catch(error => error.response)
}

export const downloadList = async (params) => {
  return await API
    .get('/users/userslist', { params })
    .catch(error => error.response)
}

// ADDRESSES

export const getAddresses = async () => {
  return await API
    .get('/users/addresses')
    .catch(error => error.response)
}

export const reqUploadAddress = async (newAddress) => {
  return await API
    .post('/users/uploadaddress', newAddress)
    .catch(error => error.response)
}

export const deleteAddress = async (params) => {
  return await API
    .delete('/users/deleteaddress', { params })
    .catch(error => error.response)
}

export const getPoblaciones = async (params) => {
  return await API
    .get('/users/poblaciones', { params })
    .catch(error => error.response)
}

export const getPaises = async () => {
  return await API
    .get('/users/paises')
    .catch(error => error.response)
}

// ORDERS

export const getOrders = async (params) => {
  return await API
    .get('/users/orders', { params })
    .catch(error => error.response)
}

export const getOrderDetails = async (params) => {
  return await API
    .get('/users/orderdetail', { params })
    .catch(error => error.response)
}

// INCIDENTS

export const getIncidents = async (params) => {
  return await API
    .get('/users/incidents', { params })
    .catch(error => error.response)
}

export const getIncidentDetail = async (params) => {
  return await API
    .get('/users/incidentdetail', { params })
    .catch(error => error.response)
}

export const getIncidentsType = async () => {
  return await API
    .get('/users/incidentstype')
    .catch(error => error.response)
}

export const createNewIncident = async (newIncident) => {
  return await API
    .post('/users/newincident', newIncident, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .catch(error => error.response)
}

// INVOICES

export const getInvoices = async (params) => {
  return await API
    .get('/users/invoices', { params })
    .catch(error => error.response)
}

export const getInvoicePdf = async (params) => {
  return await API
    .get('/users/invoicepdf', { params })
    .catch(error => error.response)
}

// MESSAGES

export const getMessages = async (params) => {
  return await API
    .get('/users/messages', { params })
    .catch(error => error.response)
}
// SHIPPING

export const getTracking = async (params) => {
  return await API
    .get('/users/tracking', { params })
    .catch(error => error.response)
}

// CONTACT

export const sendContact = async (formData) => {
  return await API
    .post('/users/contact', formData)
    .catch(error => error.response)
}

// STOCK

export const stockRequest = async (params) => {
  return await API
    .get('/stock', { params })
    .catch(error => error.response)
}

export const getPiezaById = async (piezaId) => {
  return await API
    .get(`/stock/pieza/${piezaId}`)
    .catch(error => error.response)
}

export const getStockStatus = async (piezaId) => {
  return await API
    .get(`/stock/status/${piezaId}`)
    .catch(error => error.response)
}

// FILTROS

export const filtrosRequest = async (filtro, params) => {
  return await API
    .get(`/stock/${filtro}`, { params })
    .catch(error => error.response)
}

// CART

export const addToCartRequest = async (formData) => {
  return await API
    .post('/stock/addtocart', formData)
    .catch(error => error.response)
}

export const deleteToCartRequest = async (formData) => {
  return await API
    .post('/stock/deletetocart', formData)
    .catch(error => error.response)
}

export const getValidatedCart = async (params) => {
  return await API
    .get('/users/validatedcart', { params })
    .catch(error => error.response)
}

// FAVORITES

export const addToFavoritesRequest = async (piezaStockId) => {
  return await API
    .post('/users/addtofavorites', piezaStockId)
    .catch(error => error.response)
}

export const deleteToFavoritesRequest = async (params) => {
  return await API
    .delete('/users/deletetofavorites', { params })
    .catch(error => error.response)
}

// EMAIL NO STOCK DATA

export const orderPartNotFound = async (data) => {
  return await API
    .post('/users/partnotfound', data)
    .catch(error => error.response)
}
