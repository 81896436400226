import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import PropTypes from 'prop-types'

import './inputPaises.scss'

const InputPaises = ({ register, value, onChange, paises }) => {
  return (
    <FormControl id='input-paises' sx={{ m: 1, minWidth: 120 }}>
        <Select
          {...register('input-paises', { required: 'required' })}
          value={value}
          onChange={onChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {paises?.map(pais => <MenuItem key={pais?.id} value={pais?.id}>{pais?.nombre}</MenuItem>)}
        </Select>
      </FormControl>
  )
}

InputPaises.propTypes = {
  register: PropTypes.func,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  paises: PropTypes.array.isRequired
}

export default InputPaises
