import PropTypes from 'prop-types'
import PedidoCard from '../PedidoCard'
import { useTranslation } from 'react-i18next'

import './pedidosContainerDesk.scss'
import Pagination from '../../../../shared/Pagination'
import useScreenWidth from '../../../../../hooks/useScreenWidth'
import SkeletonPedidoCard from '../SkeletonPedidoCard'

const PedidosContainerDesk = ({ pedidosData, currentPage, totalCount, pageSize, onPageChange, setIsSeguimientoOpen, setIsDetallesPedidoOpen, setPedidoToShowInfo, startNuevaIncidencia, isLoading }) => {
  const { t } = useTranslation()
  const screenWidth = useScreenWidth()

  return <div className='pedidos-container-desk'>
        <p className='pedidos-container-desk__header'>
            <span className='pedidos-container-desk__header--left-align'>{t('area-personal.pedidos.pedido')}</span>
            <span className='pedidos-container-desk__header--left-align'>{t('area-personal.pedidos.pieza')}</span>
            {screenWidth >= 1200 && <>
            <span>{t('area-personal.pedidos.precio')}</span>
            <span>{t('area-personal.pedidos.envio')}</span>
            <span>{t('area-personal.pedidos.total')}</span>
            <span className='pedidos-container-desk__header--acciones'>{t('area-personal.pedidos.acciones')}</span>
            </>}
        </p>
        {!isLoading
          ? <>
           {pedidosData?.map(pedido => <PedidoCard
                key={pedido?.id}
                pedido={pedido}
                setIsSeguimientoOpen={setIsSeguimientoOpen}
                setIsDetallesPedidoOpen={setIsDetallesPedidoOpen}
                startNuevaIncidencia={startNuevaIncidencia}
                setPedidoToShowInfo={setPedidoToShowInfo} />)}
            {pedidosData.length < 1 && <div className='facturas-container-desk__container-cards--nodata'>{t('area-personal.pedidos.nodata')}</div>}
        </>
          : <><SkeletonPedidoCard/><SkeletonPedidoCard/><SkeletonPedidoCard/><SkeletonPedidoCard/></>}
         {pedidosData?.length > 0 && <Pagination
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={pageSize}
              onPageChange={onPageChange}
          />}
    </div>
}

PedidosContainerDesk.propTypes = {
  pedidosData: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  setIsSeguimientoOpen: PropTypes.func,
  setIsDetallesPedidoOpen: PropTypes.func,
  setPedidoToShowInfo: PropTypes.func,
  startNuevaIncidencia: PropTypes.func,
  isLoading: PropTypes.bool.isRequired
}

export default PedidosContainerDesk
