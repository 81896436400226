import { useTranslation } from 'react-i18next'
import { GiCheckMark } from 'react-icons/gi'
import { useForm } from 'react-hook-form'
import { useState } from 'react'

import { MdCancel } from 'react-icons/md'
import { toast } from 'react-toastify'

import { FaExclamation } from 'react-icons/fa6'
import { createNewUser } from '../../../../services/api'
import Spinner from '../../../shared/Spinner'

const FormRegister = () => {
  const { t } = useTranslation()
  const { register, handleSubmit, formState: { errors }, reset } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isNewIdCrm, setIsNewIdCrm] = useState(null)
  const [isNewEmail, setIsNewEmail] = useState(null)

  const onSubmit = async (data) => {
    setIsLoading(true)
    const res = await createNewUser(data)
    if (res?.status === 200) {
      if (res?.data?.mensaje === 'ok') {
        setIsLoading(false)
        return true
      }
      setIsLoading(false)
      resetAllData()
      return toast.success(res.data.mensaje)
    }
    toast.error(res?.data?.error || t('errors.1'))
    setIsLoading(false)
    return false
  }

  const resetAllData = () => {
    reset()
    setIsNewIdCrm(null)
    setIsNewEmail(null)
  }

  const checkNewId = async (e) => {
    isNewIdCrm && setIsNewIdCrm(null)
    if (e.target.value.length === 0) return
    const isNew = await onSubmit({ id: e.target.value })
    if (isNew) return setIsNewIdCrm('isNew')
    return setIsNewIdCrm('AlreadyExists')
  }

  const checkNewEmail = async (e) => {
    isNewEmail && setIsNewEmail(null)
    if (e.target.value.length === 0) return
    const isNew = await onSubmit({ email: e.target.value })
    if (isNew) return setIsNewEmail('isNew')
    return setIsNewEmail('AlreadyExists')
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className='register__container--form'>
        <div>
          <label>
            {t('register.id')}
            <input type='text'
              {...register('id', {
                required: 'requerido',
                minLength: 1,
                pattern: { value: /^[0-9]/ }
              })}
              onBlur={checkNewId} />
            <span className='register__container--form--eye'>
            {isNewIdCrm
              ? isNewIdCrm === 'isNew'
                ? <GiCheckMark />
                : <FaExclamation className='register__container--form--eye--orange' />
              : ''}
            </span>
          </label>
          {errors.id && <span role="alert" className='register__container--form--error'><span><MdCancel/></span> {t('register.errors.id')}</span>}
        </div>
        <div>
          <label>
            {t('register.email')}
            <input type='email'
              {...register('email', {
                required: 'requerido',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'formato'
                }
              })}
              onBlur={checkNewEmail} />
            <span className='register__container--form--eye'>
            {isNewEmail
              ? isNewEmail === 'isNew'
                ? <GiCheckMark />
                : <FaExclamation className='register__container--form--eye--orange' />
              : '' }
            </span>
          </label>
          {errors.email && <span role="alert" className='register__container--form--error'><MdCancel/> {t(`login.form.errors.email.${errors.email.message}`)}</span>}
        </div>
        <div>
          <label>
            {t('register.name')}
            <input type='text'
              {...register('name', {
                required: 'requerido',
                minLength: 3
              })}/>
          </label>
          {errors.name && <span role="alert" className='register__container--form--error'><span><MdCancel/></span> {t('register.errors.nombre')}</span>}
        </div>
        <button className='register__container--form--btn'>{isLoading ? <Spinner className='spinner-login'/> : t('register.submit')}</button>
    </form>
  )
}

export default FormRegister
