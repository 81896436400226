import { combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { authReducer } from './reducers/authReducer'
import { configureStore } from '@reduxjs/toolkit'
import { cartReducer } from './reducers/cartReducer'
import { favoritesReducer } from './reducers/favoritesReducer'
import { filtersReducer } from './reducers/filtersReducer'

const reducers = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  favorites: favoritesReducer,
  filters: filtersReducer
})

export const store = configureStore({
  reducer: reducers,
  middleware: [thunk],
  devTools: true
})
