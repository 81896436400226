import { GrPrevious, GrNext } from 'react-icons/gr'

const SkeletonDeskCarousel = () => {
  return (
    <div className='desk-carousel skeleton-desk-carousel'>
    <div className='desk-carousel__big-picture skeleton-desk-carousel__big-picture'>
        <span className="desk-carousel__big-picture--img skeleton-desk-carousel__big-picture--picture carousel-loading-animation"></span>
    </div>
    <div className='desk-carousel__slider skeleton-desk-carousel__slider'>
        <span className='desk-carousel__slider--btn-disactive'>
            <GrPrevious />
        </span>
        <ul className="desk-carousel__slider--container-imgs">
                <li className="desk-carousel__slider--container-imgs--li skeleton-desk-carousel__imgs">
                    <span className="desk-carousel__slider--container-imgs--li--img skeleton-desk-carousel__imgs--img carousel-loading-animation"></span>
                </li>
                <li className="desk-carousel__slider--container-imgs--li skeleton-desk-carousel__imgs">
                    <span className="desk-carousel__slider--container-imgs--li--img skeleton-desk-carousel__imgs--img carousel-loading-animation"></span>
                </li>
                <li className="desk-carousel__slider--container-imgs--li skeleton-desk-carousel__imgs">
                    <span className="desk-carousel__slider--container-imgs--li--img skeleton-desk-carousel__imgs--img carousel-loading-animation"></span>
                </li>
        </ul>
        <span className='desk-carousel__slider--btn-disactive'>
            <GrNext/>
        </span>
    </div>
</div>
  )
}

export default SkeletonDeskCarousel
