import React from 'react'
import PropTypes from 'prop-types'

import './skeletonDirCard.scss'

const SkeletonDirCard = ({ isBtn = false }) => {
  return (
    <div className='skeleton-dir-card'>
      <div>
        <p className="line line1 loading-animation"></p>
        <p className="line line2 loading-animation"></p>
        <p className="line line3 loading-animation"></p>
      </div>
      {isBtn && <div className='skeleton-dir-card__btns'>
        <span className='btn-delete-edit loading-animation'></span>
        </div>}
    </div>
  )
}

SkeletonDirCard.propTypes = {
  isBtn: PropTypes.bool
}

export default SkeletonDirCard
