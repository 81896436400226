import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CgClose } from 'react-icons/cg'

import './modalVerIncidencia.scss'
import { useTranslation } from 'react-i18next'
import { getIncidentDetail } from '../../../../../services/api'
import EstadoRow from './EstadoRow'
import EachFile from './EachFile'
import SkeletonEstadoRow from './Skeletons/SkeletonEstadoRow'
import SkeletonEachFile from './Skeletons/SkeletonEachFile'

import { toast } from 'react-toastify'

const ModalVerIncidencia = ({ setIsVerIncidenciaOpen, incidenciaData }) => {
  const [delayOpen, setDelayOpen] = useState(false)
  const { t } = useTranslation()
  const [incidencia, setIncidencia] = useState(incidenciaData)

  const reqGetIncidentDetail = async () => {
    const res = await getIncidentDetail({ incidenciaId: incidenciaData?.id })
    if (res?.status === 200) {
      if (res.data?.documentacion?.length > 0) {
        const filesDecoded = res.data?.documentacion?.map(file => {
          const blob = base64toBlob(file.data)
          return { ...file, blob }
        })
        return setIncidencia({ ...incidencia, ...res.data, documentacion: filesDecoded })
      }
      return setIncidencia({ ...incidencia, ...res.data })
    } else {
      toast.error(t('errors.1'))
    }
  }

  const base64toBlob = (base64, contentType = 'application/octet-stream') => {
    const byteCharacters = atob(base64)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
  }

  useEffect(() => {
    reqGetIncidentDetail()
    window.scrollTo(0, 0)
    setDelayOpen(true)
  }, [])

  const handleDownload = async (fileData) => {
    const url = URL.createObjectURL(fileData.blob)
    const link = document.createElement('a')
    link.href = url
    link.download = fileData.name
    link.click()
  }

  return <>
        <div className={`modal-ver-incidencia ${delayOpen ? 'modal-ver-incidencia--open' : 'modal-ver-incidencia--close'}`}>
            <div className='modal-ver-incidencia__container'>
                <CgClose
                    className='modal-ver-incidencia__container--close'
                    onClick={() => setIsVerIncidenciaOpen(false)} />
                <h2 className='modal-ver-incidencia__container--title'>{t('area-personal.incidencias.modals.ver-incidencia.title')}</h2>
                {incidencia && <>
                    <h3 className='modal-ver-incidencia__container--numIncidencia'>{incidencia?.numero}</h3>
                    <h4 className='modal-ver-incidencia__container--tipo'>{incidencia?.tipo_incidencia}</h4>
                    <section>
                        <p className='modal-ver-incidencia__container--titles titles-factura-pieza'>
                            <span>{t('area-personal.incidencias.factura')}</span>
                            <span>{t('area-personal.incidencias.pieza')}</span>
                        </p>
                        <div className='modal-ver-incidencia__container--card'>
                            <div className='modal-ver-incidencia__container--card--info'>
                                <span className='modal-ver-incidencia__container--card--info--factura'>{incidencia?.venta}</span>
                                <p className='modal-ver-incidencia__container--card--info--pieza'>
                                    <span>{incidencia?.pieza[0]?.tipo_pieza}</span>
                                    <span>{incidencia?.pieza[0]?.marca} {incidencia?.pieza[0]?.modelo}</span>
                                    <span>{incidencia?.pieza[0]?.id}</span>
                                </p>
                            </div>
                            <p className='modal-ver-incidencia__container--titles titles-fecha-estado'>
                                <span>{t('area-personal.incidencias.modals.ver-incidencia.fecha')}</span>
                                <span>{t('area-personal.incidencias.estado')}</span>
                                <span className='modal-ver-incidencia__container--titles--detalle'>{t('area-personal.incidencias.modals.ver-incidencia.detalle')}</span>
                            </p>
                            {
                              incidencia.estados
                                ? incidencia.estados.length === 0
                                  ? <EstadoRow incidencia={incidenciaData} />
                                  : incidencia?.estados?.slice(0).reverse().map(incidencia => <EstadoRow incidencia={incidencia} key={incidencia?.id} />)
                                : <SkeletonEstadoRow/>
                            }
                            <div className='modal-ver-incidencia__container--card--prueba'>
                                <span className='modal-ver-incidencia__container--card--prueba--title'>
                                    {t('area-personal.incidencias.modals.ver-incidencia.prueba')}
                                </span>
                                <div className='modal-ver-incidencia__container--card--prueba--container'>
                                  {incidencia?.documentacion
                                    ? incidencia?.documentacion?.map((file, idx) => <EachFile key={'file' + idx} file={file} handleDownload={handleDownload} />)
                                    : <>
                                        <SkeletonEachFile/>
                                        <SkeletonEachFile/>
                                      </>
                                  }
                                  {incidencia?.documentacion?.length < 1 && <span className='modal-ver-incidencia__container--card--prueba--container--nodata'>{t('area-personal.incidencias.modals.ver-incidencia.nodata.1')}</span>}
                                </div>
                            </div>
                            <p className='modal-ver-incidencia__container--card--comentarios'>
                                <span className='modal-ver-incidencia__container--card--comentarios--title'>{t('area-personal.incidencias.modals.nueva-incidencia.comentarios')}</span>
                                {incidencia?.comentarios_cliente
                                  ? <span className='modal-ver-incidencia__container--card--comentarios--text'>{incidencia?.comentarios_cliente}</span>
                                  : incidencia?.comentarios_cliente === null
                                    ? <span className='modal-ver-incidencia__container--card--comentarios--text--nodata'>{t('area-personal.incidencias.modals.ver-incidencia.nodata.2')}</span>
                                    : <span className='skeleton-comentarios-cliente detalle-incidencia-loading-animation'></span>}
                            </p>
                        </div>
                    </section>
                </>}
            </div>
        </div>
        <div className="modal-ver-incidencia__overlay" />
    </>
}

ModalVerIncidencia.propTypes = {
  setIsVerIncidenciaOpen: PropTypes.func.isRequired,
  incidenciaData: PropTypes.object.isRequired
}

export default ModalVerIncidencia
