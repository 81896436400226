import { types } from '../types'

export const authReducer = (state = null, action) => {
  switch (action.type) {
    case types.login:
      return action.payload
    case types.updateUser:
      return action.payload
    case types.updateAddresses:
      return {
        ...state,
        direcciones: action.payload
      }

    default:
      return state
  }
}
