import { useEffect, useState } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import InfoPieza from '../../../../../shared/InfoPieza'

import './modalDetalleEachCard.scss'
import useScreenWidth from '../../../../../../hooks/useScreenWidth'
import Image from '../../../../../shared/Image'

const ModalDetalleEachCard = ({ pieza, iva }) => {
  const { t } = useTranslation()
  const screenWidth = useScreenWidth()
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [imgSrc, setImgSrc] = useState('')

  useEffect(() => {
    if (typeof pieza?.imgs === 'object' && pieza?.imgs?.length >= 0) {
      return setImgSrc('')
    }
    setImgSrc(pieza?.imgs?.split(',')[0] || '')
  }, [])

  return (
        <div className='modal-detalle-each-card'>
            <div className='modal-detalle-each-card__main'>
                <span className='modal-detalle-each-card__main--img'>
                    <Image foto={imgSrc} />
                </span>
                <div className='modal-detalle-each-card__main--text'>
                    <p>{pieza?.tipo_pieza?.nombre}</p>
                    <p>{pieza?.marca?.nombre} {pieza?.modelo?.nombre}</p>
                </div>
                { screenWidth >= 992 && <>
                    <div className='modal-detalle-each-card__dropdown--section-price--oem-id'>
                        <p>OEM: <span>{pieza?.referencia_pieza}</span></p>
                        <p>ID: <span>{pieza?.pieza_integracion_id || '-'}</span></p>
                    </div>
                    <span className='modal-detalle-each-card__main--price'>{(pieza?.precio_venta).toFixed(2)}€</span>
                    <span className='modal-detalle-each-card__main--envio'>{(pieza?.servicio_envio?.precio_transporte || pieza?.precio_transporte || 0).toFixed(2)}€</span>
                </>}
                <span className='modal-detalle-each-card__main--arrow'
                    onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
                    {isDropDownOpen ? <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}
                </span>
            </div>
            {isDropDownOpen && <div className='modal-detalle-each-card__dropdown'>
                { screenWidth < 992 &&
                <div className='modal-detalle-each-card__dropdown--section-price'>
                    <p className='modal-detalle-each-card__dropdown--section-price--grid'>
                        <span>{t('area-personal.pedidos.precio')}</span>
                        <span>{t('area-personal.pedidos.envio')}</span>
                        <span>{t('area-personal.pedidos.total')}</span>
                    </p>
                    <p className='modal-detalle-each-card__dropdown--section-price--grid'>
                        <span>{(pieza?.precio_venta).toFixed(2)}€</span>
                        <span>{(pieza?.servicio_envio?.precio_transporte || 0).toFixed(2)}€</span>
                        <span>{(((pieza?.precio_venta + (pieza?.servicio_envio?.precio_transporte || pieza?.precio_transporte || 0)) * iva / 100) + pieza?.precio_venta + (pieza?.servicio_envio?.precio_transporte || pieza?.precio_transporte || 0)).toFixed(2)}€</span>
                    </p>
                    <div className='modal-detalle-each-card__dropdown--section-price--oem-id'>
                        <p>OEM: <span>{pieza?.referencia_pieza}</span></p>
                        <p>ID: <span>{pieza?.pieza_integracion_id}</span></p>
                    </div>
                </div>}
                <div className='modal-detalle-each-card__dropdown--more-info'>
                    <div className='modal-detalle-each-card__dropdown--more-info--sku-obs'>
                        <p className='modal-detalle-each-card__dropdown--more-info--sku-obs--obs'>
                            <span>{t('catalogo-piezas.pieza-card.observaciones')}</span>
                            <span>{pieza?.notapublica || '-'}</span></p>
                    </div>
                    <div className='modal-detalle-each-card__dropdown--info-pieza'>
                        <InfoPieza pieza={pieza}/>
                    </div>
                </div>
            </div>}
        </div>
  )
}

ModalDetalleEachCard.propTypes = {
  pieza: PropTypes.object.isRequired,
  iva: PropTypes.number.isRequired
}

export default ModalDetalleEachCard
