import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { BiSolidFilePdf } from 'react-icons/bi'

import './facturaCard.scss'
import { getInvoicePdf } from '../../../../../services/api'
import { toast } from 'react-toastify'
import { useState } from 'react'
import Spinner from '../../../../shared/Spinner'

const FacturaCard = ({ factura }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const handleDownloadInvoice = async () => {
    if (isLoading) return

    setIsLoading(true)
    const res = await getInvoicePdf({ numFactura: factura?.numero })
    if (res?.status === 200) {
      const linkSource = `data:application/pdf;base64,${res.data}`
      const downloadLink = document.createElement('a')
      const fileName = factura?.numero + '.pdf'
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    } else {
      toast.error(t('errors.1'))
    }
    return setIsLoading(false)
  }

  const checkIfIsAlbaran = (numFra) => {
    if (numFra.includes('ALB')) return true
    if (numFra.includes('DAL')) return true
    if (numFra.includes('CAL')) return true
    return false
  }

  return (
    <div className='factura-card'>
      <div className='factura-card__num-pieza'>
        <div className='factura-card__num-pieza--numero'>
          <span className='factura-card__num-pieza--numero--num'>{factura?.numero}</span>
          <span className='factura-card__num-pieza--numero--fecha'>{factura?.fecha}</span>
        </div>
        <div className='factura-card__num-pieza--pieza'>
          {factura?.piezas?.map(pieza => {
            return (
              <p key={pieza?.id} className='factura-card__num-pieza--pieza--each'>
                <span className='factura-card__num-pieza--pieza--each--nombre'>{pieza?.tipo_pieza}</span>
                <span>{pieza?.marca} {pieza?.modelo}</span>
              </p>)
          })}
        </div>
      </div>
      <div className='factura-card__titles'>
        <span>{t('area-personal.facturas.presupuesto')}</span>
        <span>{t('area-personal.facturas.precio')}</span>
        <span>{t('area-personal.facturas.envio')}</span>
        <span>{t('area-personal.facturas.total')}</span>
      </div>
      <div className='factura-card__info-precio'>
        <span className='factura-card__info-precio--presupuesto'>{factura?.presupuesto}</span>
        <span>{factura?.total}€</span>
        <span>{factura?.total_envio}€</span>
        <span>{factura?.total_iva}€</span>
      </div>
      <div className='factura-card__titles'>
        <span>{t('area-personal.facturas.estado')}</span>
        <span>{t('area-personal.facturas.pago')}</span>
      </div>
      <div className='factura-card__envio-container'>
        <span className='factura-card__envio-container--estado'>{factura?.estado}</span>
        <span>{factura?.tipo_pago}</span>
      </div>
      <div className='factura-card__descargar'>
        <span className='factura-card__descargar--icon' onClick={handleDownloadInvoice}>{isLoading ? <Spinner className='spinner-descargar-factura'/> : <BiSolidFilePdf/>}</span>
        <span className='factura-card__descargar--text' onClick={handleDownloadInvoice}>{
          checkIfIsAlbaran(factura?.numero) ? t('area-personal.facturas.descargar-alb') : t('area-personal.facturas.descargar')}</span>
      </div>
    </div>
  )
}

FacturaCard.propTypes = {
  factura: PropTypes.object.isRequired
}

export default FacturaCard
