/* eslint-disable react/prop-types */
import './pagination.scss'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

import { usePagination, DOTS } from '../../../hooks/usePagination'
import { useEffect } from 'react'

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    filters,
    isPrevPagePartDetail
  } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  })

  const onNext = () => {
    onPageChange(prev => prev + 1)
  }

  const onPrevious = () => {
    onPageChange(prev => prev - 1)
  }

  useEffect(() => {
    if (!filters && !isPrevPagePartDetail) {
      window.scrollTo({
        behavior: 'smooth',
        top: 0
      })
    }
  }, [currentPage])

  const lastPage = paginationRange[paginationRange.length - 1]

  return (
        <ul className='pagination-container'>
            <li
                className={`pagination-item arrow-left ${currentPage === 1 ? 'disabled' : ''}`}
                onClick={onPrevious}
            >
                <MdKeyboardArrowLeft/>
            </li>
            {paginationRange.map((pageNumber, idx) => {
              if (pageNumber === DOTS) {
                return <li
                        key={`${idx}_${pageNumber}`}
                        className="pagination-item dots">&#8230;</li>
              }

              return (
                    <li
                        key={`${idx}_${pageNumber}`}
                        className={`pagination-item ${pageNumber === currentPage ? 'selected' : ''}`}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </li>
              )
            })}
            <li
                className={`pagination-item arrow-right ${lastPage === currentPage ? 'disabled' : ''}`}
                onClick={onNext}
            >
                <MdKeyboardArrowRight/>
            </li>
        </ul>
  )
}

export default Pagination
