import { types } from '../types'

export const cartReducer = (state = {}, action) => {
  switch (action.type) {
    case types.updateCart:
      return action.payload
    case types.resetCartState:
      return {}
    default:
      return state
  }
}
