import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { MdCancel } from 'react-icons/md'
import { AiFillInfoCircle } from 'react-icons/ai'
import { FaExclamationTriangle } from 'react-icons/fa'
import './formDirEnvio.scss'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { getPaises, reqUploadAddress } from '../../../services/api'
import InputPaises from './InputPaises'
import Spinner from '../Spinner'
import { toast } from 'react-toastify'
import GooglePlacesAutocomplete from '../GooglePlacesAutocomplete'

const FormDirEnvio = ({ className = '', dirEnvio, addNewDirection }) => {
  const { t } = useTranslation()
  const { register, handleSubmit, setValue, getValues, formState: { errors }, clearErrors, watch } = useForm()
  const [paises, setPaises] = useState(null)
  const [currentPaisId, setCurrentPaisId] = useState(1)

  const [isLoading, setIsLoading] = useState(true)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)

  useEffect(() => {
    getPaisesReq()
  }, [])

  const onSaveData = async (data) => {
    if (isLoading) return
    // check outside peninsula
    const areaLowCase = data.area.toLowerCase()
    if (((Number(data.pais_id) !== 1 && Number(data.pais_id) !== 10) ||
        (Number(data.pais_id) === 1 && data.codigo_postal >= '51001' && data.codigo_postal <= '51080') || // CEUTA
        (Number(data.pais_id) === 1 && data.codigo_postal >= '52001' && data.codigo_postal <= '52080') || // MELILLA
        ((Number(data.pais_id) === 1 && data.codigo_postal >= '35001' && data.codigo_postal <= '35660') || (Number(data.pais_id) === 1 && data.codigo_postal >= '38001' && data.codigo_postal <= '38917')) || // CANARIAS
        ((Number(data.pais_id) === 10 && (data?.codigo_postal >= '9000-001' && data?.codigo_postal <= '9980-999'))) || // ISLAS PORTUGAL
        (areaLowCase.includes('ceuta') || areaLowCase.includes('melilla') || areaLowCase.includes('canarias') || areaLowCase.includes('madeira') || areaLowCase.includes('azores') || areaLowCase.includes('açores'))
    )) { return toast.error(t('errors.fuera-rango')) }

    setIsUpdateLoading(true)

    const pais = paises?.find(pais => pais?.id === currentPaisId)

    const newDir = { ...data, pais }

    const res = await reqUploadAddress(newDir)
    if (res?.status === 200) {
      addNewDirection(res.data)
    } else {
      toast.error(res?.data?.error || t('errors.1'))
    }
    setIsUpdateLoading(false)
  }

  useEffect(() => {
    setValue('nombre', '')
    setValue('telefono', '')
    setValue('direccion', '')
    setValue('pais_id', 1)
    setValue('codigo_postal', '')
    setValue('poblacion', '')
    setValue('area', '')
  }, [])

  const getPaisesReq = async () => {
    setIsLoading(true)
    const res = await getPaises()
    if (res?.status === 200) {
      const allowedCountries = res.data.filter(country => country.id === 1 || country.id === 10)
      setPaises(allowedCountries)
    } else {
      toast.error(t('errors.1'))
    }
    return setIsLoading(false)
  }

  const handleChangePais = (e) => {
    setCurrentPaisId(e.target.value)
    setValue('pais_id', e.target.value)
  }

  const [telefonoValue, setTelefonoValue] = useState('')

  const handleTelefonoChange = (event) => {
    let inputValue = event.target.value
    if (!/^[0-9()+-]*$/.test(inputValue)) {
      inputValue = inputValue.slice(0, -1)
    }
    setTelefonoValue(inputValue)
    if (inputValue.length >= 9) clearErrors('telefono')
  }

  const handleChangeDireccion = (direccion) => {
    Object.keys(direccion).forEach(function (key) {
      setValue(key, direccion[key])
      if (direccion[key]) clearErrors(key)
    })
  }

  return (
        <form className={'form-dir-envio' + ' ' + className} onSubmit={handleSubmit(onSaveData)}>
            <h4>{t('carrito.envio.form.title')}</h4>
            <div className='form-dir-envio__div'>
                <label className='form-dir-envio__label'>
                    {t('carrito.envio.form.nombre')}
                    <input type='text'
                        {...register('nombre', {
                          required: true,
                          minLength: 1,
                          pattern: { value: /^[A-Za-z]/ }
                        })} />
                </label>
                {errors?.nombre && <span role='alert'><MdCancel/> {t('carrito.envio.form.errors.nombre')}</span>}
            </div>
            <div className='form-dir-envio__div'>
                <label className='form-dir-envio__label'>
                    {t('carrito.envio.form.telefono')}
                    <input type='text'
                        {...register('telefono', {
                          required: 'required',
                          pattern: { value: /^\S.*[0-9]+$/ },
                          minLength: {
                            value: 9,
                            message: 'minLength'
                          }
                        })}
                        value={telefonoValue}
                        onChange={handleTelefonoChange}
                        />
                </label>
                {errors?.telefono && <span role='alert'><MdCancel/> {t(`contacto.form.errors.tlf.${errors.telefono.message}`)}</span>}
            </div>
            <div className='form-dir-envio__alert info'>
              <div className='form-dir-envio__alert-div'>
                <div><AiFillInfoCircle /></div>
                <p>
                  {t('carrito.envio.form.extra_info.info')}
                  <b>{t('carrito.envio.form.extra_info.info_bold')}</b>
                  {t('carrito.envio.form.extra_info.info2')}
                </p>
              </div>
            </div>
            <div className='form-dir-envio__div-row'>
              <div className='form-dir-envio__div-small'>
                <label className='form-dir-envio__label'>
                  {t('carrito.envio.form.pais')}
                  {!isLoading
                    ? <>{paises && <InputPaises register={register} value={currentPaisId} onChange={handleChangePais} paises={paises} />}</>
                    : <div className='skeleton-input'><span className='skeleton-input__span'></span></div>}
                </label>
                  {errors?.pais_id && <span role='alert'><MdCancel/> {t('carrito.envio.form.errors.pais')}</span>}
              </div>
              <div className='form-dir-envio__div-large'>
                <label className='form-dir-envio__label'>
                  {t('carrito.envio.form.buscar')}
                  <GooglePlacesAutocomplete onSelectResult={handleChangeDireccion}
                    country={watch('pais_id') ? paises?.find(p => p.id === getValues('pais_id'))?.codigo : 'ES'}
                    disabled={false}
                  />
                </label>
              </div>
            </div>

            <div className='form-dir-envio__alert warning'>
              <div className='form-dir-envio__alert-div'>
                <div><FaExclamationTriangle /></div>
                <p>
                  {t('carrito.envio.form.extra_info.warning')}
                  <b>{t('carrito.envio.form.extra_info.warning_bold')}</b>
                  {t('carrito.envio.form.extra_info.warning2')}
                </p>
              </div>
            </div>

            <div className='form-dir-envio__div-small'>
              <label className='form-dir-envio__label'>
                  {t('carrito.envio.form.codigo_postal')}
                  <input type='text'
                      {...register('codigo_postal', {
                        required: true,
                        maxLength: 10
                      })} />
              </label>
              {errors?.codigo_postal && <span role='alert'><MdCancel/> {t('carrito.envio.form.errors.codigo_postal')}</span>}
            </div>
            <div className='form-dir-envio__div-large'>
              <label className='form-dir-envio__label'>
                  {t('carrito.envio.form.direccion')}
                  <input type='text'
                      {...register('direccion', {
                        required: true,
                        minLength: 5
                      })} />
              </label>
              {errors?.direccion && <span role='alert'><MdCancel/> {t('carrito.envio.form.errors.direccion')}</span>}
            </div>
            <div className='form-dir-envio__div'>
              <label className='form-dir-envio__label'>
                  {t('carrito.envio.form.poblacion')}
                  <input type='text'
                      {...register('poblacion', {
                        required: true,
                        maxLength: 150,
                        pattern: { value: /^[A-Za-z]/ }
                      })} />
              </label>
              {errors?.poblacion && <span role='alert'><MdCancel/> {t('carrito.envio.form.errors.poblacion')}</span>}
            </div>

            <div className='form-dir-envio__div'>
              <label className='form-dir-envio__label'>
                  {t('carrito.envio.form.area')}
                  <input type='text'
                      {...register('area', {
                        required: true,
                        maxLength: 150,
                        pattern: { value: /^[A-Za-z]/ }
                      })} />
              </label>
              {errors?.area && <span role='alert'><MdCancel/> {t('carrito.envio.form.errors.area')}</span>}
            </div>
            <div className='form-dir-envio__button'>
                <button disabled={isUpdateLoading} className='form-dir-envio__button--btn' type='submit'>{isUpdateLoading ? <Spinner className='spinner-form-dir-envio'/> : t('carrito.envio.form.guardar')}</button>
            </div>
        </form>
  )
}

FormDirEnvio.propTypes = {
  className: PropTypes.string,
  dirEnvio: PropTypes.any,
  addNewDirection: PropTypes.func,
  uploadDirection: PropTypes.func
}
export default FormDirEnvio
