import { useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'

import './reenvioEmail.scss'
import { useTranslation } from 'react-i18next'
import { editEmail, forwardingEmail, getInfoUser } from '../../../services/api'
import { MdEdit } from 'react-icons/md'
import { RiDeleteBack2Line } from 'react-icons/ri'
import { HiOutlineSave } from 'react-icons/hi'
import Spinner from '../../shared/Spinner'
import { toast } from 'react-toastify'

const ReenvioEmail = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useState('')
  const [isEditableEmail, setIsEditableEmail] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [isLoadingEmail, setIsLoadingEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [infoUser, setInfoUser] = useState({
    id: '',
    id_crm: '',
    name: '',
    email: ''
  })
  const submitSearch = async () => {
    setIsLoadingSearch(true)
    const res = await getInfoUser(searchParams)
    if (res?.status === 200) {
      setInfoUser(res?.data)
      return setIsLoadingSearch(false)
    }
    setIsLoadingSearch(false)
    return toast.error(res.data.error || t('errors.1'))
  }

  const handleEditEmail = () => {
    setNewEmail(infoUser.email)
    setIsEditableEmail(true)
  }

  const handleResetEmail = () => {
    setIsEditableEmail(false)
    setNewEmail('')
  }

  const submitEditEmail = async () => {
    setIsLoadingEmail(true)
    const res = await editEmail({ user_id: infoUser.id, new_email: newEmail })
    if (res?.status === 200) {
      setInfoUser({
        ...infoUser, email: newEmail
      })
      setIsEditableEmail(false)
      return setIsLoadingEmail(false)
    }
    toast.error(res?.data?.error || t('errors.1'))
    return setIsLoadingEmail(false)
  }

  const submitForwarding = async () => {
    if (isEditableEmail) return toast.error(t('comercial.reenvio.editable-error'))
    setIsLoading(true)
    const res = await forwardingEmail(infoUser)
    if (res?.status === 200) {
      setIsLoading(false)
      setInfoUser({
        id: '',
        id_crm: '',
        name: '',
        email: ''
      })
      setSearchParams('')
      return toast.success(res?.data?.mensaje)
    }
    setIsLoading(false)
    return toast.error(t('errors.1'))
  }

  return (
    <div className='reenvio-email register'>
      <section className='reenvio-email__search'>
        <input type='text'
          placeholder={t('comercial.reenvio.introduce')}
          value={searchParams}
          onChange={(e) => setSearchParams(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && submitSearch()}
        />
        {isLoadingSearch ? <Spinner className='simple-spinner-reenvio-email-search' /> : <AiOutlineSearch onClick={() => submitSearch()} />}
      </section>
      <div className='register__container'>
      <h1 className='register__container--title'>{t('comercial.reenvio.title')}</h1>
      <section className='register__container--form'>
        <div className='reenvio-email__each-input'>
          <span>{t('register.id')}</span>
          <span className='reenvio-email__each-input--input'>{infoUser.id_crm}</span>
        </div>
        <div className='reenvio-email__each-input'>
          <span>{t('register.email')}</span>
          <div className='reenvio-email__each-input--editable-input'>
            {isEditableEmail
              ? <>
                  <input
                    disabled={isLoadingEmail}
                    type='email'
                    value={newEmail}
                    autoFocus
                    onChange={(e) => setNewEmail(e.target.value)}
                    onKeyDown={(e) => (e.key === 'Enter' || e.key === 'Tab') && submitEditEmail()}
                    />
                  <span className='reenvio-email__each-input--editable-input--save-delete'>
                    <RiDeleteBack2Line onClick={handleResetEmail} />
                    <HiOutlineSave onClick={submitEditEmail} /></span>
                </>
              : <>
                  <span className='reenvio-email__each-input--editable-input--disabled-text'>{infoUser.email}</span>
                  {infoUser.email && <MdEdit onClick={handleEditEmail} />}
                </>}
          </div>
        </div>
        <div className='reenvio-email__each-input'>
          <span>{t('register.name')}</span>
          <span className='reenvio-email__each-input--input'>{infoUser.name}</span>
        </div>
        <div><button disabled={!infoUser.id} onClick={submitForwarding}>{isLoading ? <Spinner className='spinner-login'/> : t('register.submit')}</button></div>
      </section>
      </div>
    </div>
  )
}

export default ReenvioEmail
