import { useTranslation } from 'react-i18next'
import EachFilter from './EachFilter'
import useScreenWidth from '../../../../hooks/useScreenWidth'
import { TbTrashXFilled } from 'react-icons/tb'
import { CgClose } from 'react-icons/cg'
import PropTypes from 'prop-types'
import './filtrosSection.scss'
import WhatsAppSection from '../../../shared/WhatsAppSection'
import { useEffect, useState } from 'react'
import { filtrosRequest } from '../../../../services/api'
import { toast } from 'react-toastify'
import PriceFilter from './PriceFilter'

const FiltrosSection = ({ isModalOpen, filterValues, handleCloseModal, setFilterValues, submitFilterValues, isPrevPagePartDetail, isFromLleida }) => {
  const { t } = useTranslation()
  const screenWidth = useScreenWidth()
  const filters = ['marca', 'modelo', 'familia', 'articulo']
  const [filtrosData, setFiltrosData] = useState({ marcas: [], modelos: [], familias: [], articulos: [] })

  const getFiltrosData = async (filtro, params) => {
    const res = await filtrosRequest(filtro, params)
    if (res?.status === 200) {
      const sortedData = res.data?.sort((a, b) => {
        const nameA = a.nombre.toUpperCase()
        const nameB = b.nombre.toUpperCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      setFiltrosData(prev => ({ ...prev, [filtro]: sortedData }))
    } else {
      toast.error(t('errors.1'))
    }
  }

  const handleRequestFiltrosData = (e, filterValue) => {
    if (e.target.name === 'marca') {
      getFiltrosData('modelos', { marca: filterValue })
      return setFilterValues({
        ...filterValues,
        marca: filterValue,
        modelo: { id: '', nombre: '' },
        familia: { id: '', nombre: '' },
        articulo: { id: '', nombre: '' }
      })
    } else if (e.target.name === 'modelo') {
      getFiltrosData('familias', { marca: filterValues?.marca, modelo: filterValue })
    } else if (e.target.name === 'familia') {
      getFiltrosData('articulos', { marca: filterValues?.marca, modelo: filterValues?.modelo, familia: filterValue })
    }
    setFilterValues({ ...filterValues, [e.target.name]: filterValue })
  }

  const handleFilterValues = (e, filterValue) => {
    if (e.target.checked) {
      handleRequestFiltrosData(e, filterValue)
    } else {
      handleRemoveFilter(e.target.name)
    }
  }

  const handleRemoveFilter = (filterName) => {
    if (filterName === 'articulo') {
      setFilterValues({ ...filterValues, articulo: { id: '', nombre: '' } })
    } else if (filterName === 'familia') {
      setFilterValues({ ...filterValues, familia: { id: '', nombre: '' }, articulo: { id: '', nombre: '' } })
      setFiltrosData({ ...filtrosData, articulos: [] })
    } else if (filterName === 'modelo') {
      setFilterValues({ ...filterValues, modelo: { id: '', nombre: '' }, familia: { id: '', nombre: '' }, articulo: { id: '', nombre: '' } })
      setFiltrosData({ ...filtrosData, familias: [], articulos: [] })
    } else {
      setFilterValues({ ...filterValues, marca: { id: '', nombre: '' }, modelo: { id: '', nombre: '' }, familia: { id: '', nombre: '' }, articulo: { id: '', nombre: '' } })
      setFiltrosData({ ...filtrosData, modelos: [], familias: [], articulos: [] })
    };
  }

  const handleResetFilters = () => {
    setFilterValues({
      marca: { id: '', nombre: '' },
      modelo: { id: '', nombre: '' },
      familia: { id: '', nombre: '' },
      articulo: { id: '', nombre: '' },
      precioMin: '',
      precioMax: ''
    })
    setFiltrosData({ ...filtrosData, modelos: [], familias: [], articulos: [] })
    screenWidth >= 768 && submitFilterValues('resetFilters')
  }

  // petició a la api de marcas quan carrega la pag
  useEffect(() => {
    getFiltrosData('marcas')
  }, [])

  // petició a la resta de filtres quan isPrePagePartDetail quan carrega la pag
  useEffect(() => {
    if (isPrevPagePartDetail) {
      if (filterValues?.marca?.id) getFiltrosData('modelos', { marca: filterValues?.marca })
      if (filterValues?.modelo?.id) getFiltrosData('familias', { marca: filterValues?.marca, modelo: filterValues?.modelo })
      if (filterValues?.familia?.id) getFiltrosData('articulos', { marca: filterValues?.marca, modelo: filterValues?.modelo, familia: filterValues?.familia })
    }
  }, [filterValues])

  return (
        <><div className={`filtros-section ${isFromLleida ? 'from-lleida' : ''} ${isModalOpen ? 'filtros-section--open' : 'filtros-section--close'}`}>
            {screenWidth < 768 &&
                <div className='filtros-section__mb-top-btns'>
                    <span className='filtros-section__mb-top-btns--borrar'
                        onClick={handleResetFilters}>
                        <TbTrashXFilled />
                        {t('catalogo-piezas.filtros-seccion.borrar-todo')}
                    </span>
                    <span className='filtros-section__mb-top-btns--close'
                        onClick={handleCloseModal}><CgClose /></span>
                </div>
            }
            {
              filters?.map((filter, idx) =>
                  <EachFilter
                      key={filter}
                      isPrevPagePartDetail={isPrevPagePartDetail}
                      nombre={t(`catalogo-piezas.filtros-seccion.${filter}`)}
                      data={filtrosData[filter + 's']}
                      onChange={handleFilterValues}
                      inputName={filter}
                      filterValues={filterValues}
                      handleRemoveFilter={handleRemoveFilter}
                      isActive={idx === 0 ? true : filterValues[filters[idx - 1]]?.id !== ''} />)
            }
            <PriceFilter
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              handleFilterValues={handleFilterValues} />
            { screenWidth >= 768 && <div className='filtros-section__mb-top-btns'>
              <span className='filtros-section__mb-top-btns--borrar'
                  onClick={handleResetFilters}>
              <TbTrashXFilled />
              {t('catalogo-piezas.filtros-seccion.borrar-todo')}
              </span></div>
            }
            <div className='filtros-section__mb-btn-container'>
              <button onClick={submitFilterValues}>{t('catalogo-piezas.filtros-seccion.aplicar')}</button>
            </div>
            {isModalOpen && <WhatsAppSection/>}
        </div>
        {isModalOpen && screenWidth < 768 && <div className='filtros-section__overlay' />}</>)
}

FiltrosSection.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  filterValues: PropTypes.object.isRequired,
  setFilterValues: PropTypes.func.isRequired,
  submitFilterValues: PropTypes.func.isRequired,
  isPrevPagePartDetail: PropTypes.bool.isRequired,
  isFromLleida: PropTypes.bool.isRequired
}

export default FiltrosSection
