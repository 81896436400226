import useScreenWidth from '../../../../../../hooks/useScreenWidth'
import { MdKeyboardArrowDown } from 'react-icons/md'

import './skeletonDetalleEachCard.scss'

const SkeletonDetalleEachCard = () => {
  const screenWidth = useScreenWidth()
  return (
    <div className='modal-detalle-each-card skeleton-detalle-each-card'>
            <div className='modal-detalle-each-card__main'>
                <span className='modal-detalle-each-card__main--img detalle-each-card-loading-animation'></span>
                <div className='modal-detalle-each-card__main--text'>
                    <p className='skeleton-detalle-each-card__nombre detalle-each-card-loading-animation'></p>
                    <p className='skeleton-detalle-each-card__modelo detalle-each-card-loading-animation'></p>
                </div>
                { screenWidth >= 992 && <>
                    <div className='skeleton-detalle-each-card__labels'>
                        <p className='skeleton-detalle-each-card__labels--label1 detalle-each-card-loading-animation'></p>
                        <p className='skeleton-detalle-each-card__labels--label2 detalle-each-card-loading-animation'></p>
                    </div>
                    <span className='modal-detalle-each-card__main--price skeleton-detalle-each-card__precio detalle-each-card-loading-animation'></span>
                    <span className='modal-detalle-each-card__main--envio skeleton-detalle-each-card__envio detalle-each-card-loading-animation'></span>
                </>}
                <span className='modal-detalle-each-card__main--arrow'>
                    <MdKeyboardArrowDown/>
                </span>
            </div>
    </div>
  )
}

export default SkeletonDetalleEachCard
