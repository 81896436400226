import { useTranslation } from 'react-i18next'
import { BsDownload } from 'react-icons/bs'

import './listados.scss'
import { downloadList } from '../../../services/api'
import { toast } from 'react-toastify'
import { useState } from 'react'
import Spinner from '../../shared/Spinner'

const Listados = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const listNames = ['registrados', 'no_registrados']

  const handleDownload = async (listName) => {
    setIsLoading(listName)
    const res = await downloadList({ listName })
    if (res?.status === 200) {
      const blob = new Blob([res.data], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `usuarios_${listName}.csv`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      return setIsLoading(false)
    }
    setIsLoading(false)
    return toast.error(t('errors.1'))
  }
  return (
    <div className='listados'>
      <h1>{t('comercial.listados.title')}</h1>
      <div className='listados__container'>
        {
          listNames.map(name => (
            <div className='listados__container--each-card' key={name}>
              <h2>
                {t(`comercial.listados.${name}`)}
              </h2>
              <p>{t(`comercial.listados.${name}-text`)}</p>
              <span className='listados__container--each-card--icon'>
                <span className='listados__container--each-card--icon--span'>{isLoading && isLoading === name ? <Spinner className='spinner-eliminar-pieza' /> : <BsDownload onClick={() => handleDownload(name)} />}</span>
              </span>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Listados
