import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import IncidenciaCard from '../IncidenciaCard'

import './incidenciasContainerMob.scss'
import SkeletonIncidenciaCard from '../SkeletonIncidenciaCard'

const IncidenciasContainerMob = ({ incidenciasData, observerTarget, handleVerIncidenciaModal, isLoading }) => {
  const { t } = useTranslation()

  return <section className='incidencias-container-mob'>
      <p className='incidencias-container-mob__header'>
            <span>{t('area-personal.incidencias.num')}</span>
            <span>{t('area-personal.incidencias.pieza')}</span>
        </p>
        {incidenciasData?.map(incidencia =>
            <IncidenciaCard key={incidencia?.numero} incidencia={incidencia} handleVerIncidenciaModal={handleVerIncidenciaModal} />
        )}
        {isLoading && <><SkeletonIncidenciaCard/><SkeletonIncidenciaCard/><SkeletonIncidenciaCard/><SkeletonIncidenciaCard/></>}
        {!isLoading && incidenciasData?.length < 1 && <div className='facturas-container-mob__container-cards--nodata'>{t('area-personal.incidencias.nodata')}</div>}
        <div ref={observerTarget}></div>
    </section>
}

IncidenciasContainerMob.propTypes = {
  incidenciasData: PropTypes.array.isRequired,
  observerTarget: PropTypes.any.isRequired,
  handleVerIncidenciaModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default IncidenciasContainerMob
