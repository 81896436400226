import { TbTrashXFilled } from 'react-icons/tb'
import PropTypes from 'prop-types'

import './eachDirCard.scss'
import { useTranslation } from 'react-i18next'

const EachDirCard = ({ direccion, handleDelete, isDirEnvio = true, isOutOfRange }) => {
  const { t } = useTranslation()
  return <div className={`each-dir-card ${isOutOfRange && isDirEnvio ? ' out-of-range' : ''}`}>
        <div className={isDirEnvio ? 'each-dir-card__direccion' : ''}>
            <h3>{direccion?.nombre}</h3>
            {!isDirEnvio && <h4>{direccion?.cif}</h4>}
            <p>{direccion?.direccion},</p>
            <p>{direccion?.codigo_postal} {direccion?.poblacion}, {direccion?.area}, {direccion?.pais?.nombre}</p>
            {isDirEnvio && isOutOfRange &&
              <p className='each-dir-card__text-out-of-range'>({t('errors.fuera-rango')})</p>
            }
        </div>
        {isDirEnvio && !isOutOfRange && <div className='each-dir-card__btns'>
            <span onClick={() => handleDelete(direccion?.id)}><TbTrashXFilled /></span>
        </div>}
    </div>
}

EachDirCard.propTypes = {
  direccion: PropTypes.object.isRequired,
  handleDelete: PropTypes.func,
  isDirEnvio: PropTypes.bool,
  isOutOfRange: PropTypes.bool
}

export default EachDirCard
