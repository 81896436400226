import { useTranslation } from 'react-i18next'
import { BiArrowBack } from 'react-icons/bi'
import { AiOutlineSearch } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md'

import './pedidosRealizados.scss'
import { useEffect, useRef, useState } from 'react'
import useScreenWidth from '../../../../hooks/useScreenWidth'
import PedidosContainerMob from './PedidosContainerMob'
import PedidosContainerDesk from './PedidosContainerDesk'
import ModalSeguimiento from './ModalSeguimiento'
import ModalDetallesPedido from './ModalDetallesPedido'
import BusquedaDropDown from './BusquedaDropDown'
import { getOrders } from '../../../../services/api'
import ModalNuevaIncidencia from './ModalNuevaIncidencia'
import { OkMessage } from '../../../shared/Alert'
import { toast } from 'react-toastify'

let PageSize = 4

const PedidosRealizados = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const screenWidth = useScreenWidth()

  // loading

  const [isLoading, setIsLoading] = useState(true)

  // busqueda avanzada
  const [isBusquedaOpen, setIsBusquedaOpen] = useState(false)
  const submitBusqueda = (data) => {
    setQuery({ busquedaAvanzada: data, searchParams })
    if (currentPage === 1) {
      reqGetOrders({ offset: currentPage, limit: PageSize, busquedaAvanzada: data, searchParams })
    } else {
      setCurrentPage(1)
    }
    setPedidosData([])
  }
  // open modals
  const [isSeguimientoOpen, setIsSeguimientoOpen] = useState(false)
  const [isDetallesPedidoOpen, setIsDetallesPedidoOpen] = useState(false)
  const [pedidoToShowInfo, setPedidoToShowInfo] = useState(null)

  // modal nueva incidencia
  const [isNuevaIncidenciaOpen, setIsNuevaIncidenciaOpen] = useState(false)
  const [isOkMessageOpen, setIsOkMessageOpen] = useState(false)

  const startNuevaIncidencia = (pedido) => {
    setPedidoToShowInfo(pedido)
    setIsNuevaIncidenciaOpen(true)
  }

  const [searchParams, setSearchParams] = useState('')

  const submitSearch = () => {
    if (isLoading) return

    !hasMoreData && setHasMoreData(true)
    setQuery({ searchParams })
    if (currentPage === 1) {
      reqGetOrders({ offset: currentPage, limit: PageSize, searchParams })
    } else {
      setCurrentPage(1)
    }
    setPedidosData([])
  }

  // req get orders
  const [query, setQuery] = useState({})

  const reqGetOrders = async (paginationInfo) => {
    setIsLoading(true)
    const res = await getOrders(paginationInfo)
    if (res?.status === 200) {
      screenWidth < 992 && res.data?.pedidos?.length < PageSize && setHasMoreData(false)
      screenWidth >= 992
        ? setPedidosData(res.data?.pedidos)
        : currentPage === 1
          ? setPedidosData(res.data?.pedidos)
          : setPedidosData((prev) => [...prev, ...res.data?.pedidos])
      setTotalPedidosData(res.data?.totalPedidos)
    } else {
      toast.error(t('errors.1'))
    }
    return setIsLoading(false)
  }

  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPedidosData, setTotalPedidosData] = useState(0)
  const [pedidosData, setPedidosData] = useState([])
  const [hasMoreData, setHasMoreData] = useState(true)

  const observerTarget = useRef(null)

  useEffect(() => {
    screenWidth >= 992 ? PageSize = 4 : PageSize = 7
    hasMoreData && reqGetOrders({ ...query, offset: currentPage, limit: PageSize })
  }, [currentPage])

  useEffect(() => {
    if (screenWidth < 992) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setCurrentPage(prev => prev + 1)
          }
        },
        { threshold: 1 }
      )
      if (observerTarget.current) {
        observer.observe(observerTarget.current)
      }
      return () => {
        if (observerTarget.current) {
          observer.unobserve(observerTarget.current)
        }
      }
    }
  }, [observerTarget])

  return <div className='pedidos-realizados'>
        <span
            className='pedidos-realizados__return'
            onClick={() => navigate('/area-personal')}>
            <BiArrowBack/>
        </span>
        <h2>{t('area-personal.pedidos.title')}</h2>
        <h3>{t('area-personal.pedidos.subtitle')} ({totalPedidosData})</h3>
        <section className='pedidos-realizados__search-section'>
            <div className='pedidos-realizados__search-section--container'>
                <div className='pedidos-realizados__search-section--container--search'>
                    <input type='text'
                        value={searchParams}
                        placeholder={t('area-personal.pedidos.search')}
                        onChange={(e) => setSearchParams(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && submitSearch()} />
                    <span onClick={submitSearch}><AiOutlineSearch /></span>
                </div>
                <p className='pedidos-realizados__search-section--container--busqueda' onClick={() => setIsBusquedaOpen(!isBusquedaOpen)}>
                    {isBusquedaOpen ? <MdKeyboardArrowDown/> : <MdKeyboardArrowRight/>}
                    <span>{t('area-personal.pedidos.busqueda.title')}</span>
                </p>
            </div>
            {isBusquedaOpen && <BusquedaDropDown submitBusqueda={submitBusqueda} isLoading={isLoading} />}
        </section>
        {screenWidth < 992
          ? <PedidosContainerMob
                isLoading={isLoading}
                pedidosData={pedidosData}
                setIsSeguimientoOpen={setIsSeguimientoOpen}
                setIsDetallesPedidoOpen={setIsDetallesPedidoOpen}
                startNuevaIncidencia={startNuevaIncidencia}
                setPedidoToShowInfo={setPedidoToShowInfo}
                observerTarget={observerTarget}/>
          : <PedidosContainerDesk
                isLoading={isLoading}
                pedidosData={pedidosData}
                setIsSeguimientoOpen={setIsSeguimientoOpen}
                setIsDetallesPedidoOpen={setIsDetallesPedidoOpen}
                setPedidoToShowInfo={setPedidoToShowInfo}
                startNuevaIncidencia={startNuevaIncidencia}
                currentPage={currentPage}
                totalCount={totalPedidosData}
                pageSize={PageSize}
                onPageChange={setCurrentPage}/>
        }
        {isSeguimientoOpen && <ModalSeguimiento setIsSeguimientoOpen={setIsSeguimientoOpen} pedido={pedidoToShowInfo}/>}
        {isDetallesPedidoOpen && <ModalDetallesPedido setIsDetallesPedidoOpen={setIsDetallesPedidoOpen} pedido={pedidoToShowInfo}/>}
        {isNuevaIncidenciaOpen && <ModalNuevaIncidencia setIsNuevaIncidenciaOpen={setIsNuevaIncidenciaOpen} setIsOkMessageOpen={setIsOkMessageOpen} pedido={pedidoToShowInfo} pedidosData={pedidosData} setPedidosData={setPedidosData} />}
        {isOkMessageOpen && <OkMessage setIsOkMessageOpen={setIsOkMessageOpen} text={t('area-personal.incidencias.modals.nueva-incidencia.okMessage')}/>}
    </div>
}

export default PedidosRealizados
