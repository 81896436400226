import { useTranslation } from 'react-i18next'
import './detalleCarrito.scss'
import PropTypes from 'prop-types'
import { getStockStatus } from '../../../services/api'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import SkeletonDetalleCarrito from './SkeletonDetalleCarrito'
import EachCardDetalle from './EachCardDetalle'
import { useSelector } from 'react-redux'

const DetalleCarrito = ({ className = '', piezasStatusChecked, setPiezasStatusChecked, isPartsSusceptibleWearingCasco }) => {
  const { t } = useTranslation()
  const cart = useSelector(state => state.cart)
  const [isStockLoading, setIsStockLoading] = useState(false)

  const checkStokStatus = async () => {
    setIsStockLoading(true)
    for (const pieza of cart?.piezas) {
      const res = await getStockStatus(pieza?.pieza_integracion_id)
      if (res?.status === 200) setPiezasStatusChecked(prev => [...prev, { ...pieza, ...res.data }])
      else toast.error(t('errors.1'))
    }
    return setIsStockLoading(false)
  }

  useEffect(() => {
    if (cart?.piezas?.length > 0) {
      if (piezasStatusChecked?.length > 0) setPiezasStatusChecked([])
      checkStokStatus()
    }
  }, [cart?.validado])

  return (
        <div className="detalle-carrito">
            <div className={`detalle-carrito__header ${className}`}>
                <span className="detalle-carrito__header--producto">
                    {t('carrito.detalle.producto')}
                </span>
                <span className="detalle-carrito__header--precio">
                    {t('carrito.detalle.precio')}
                </span>
            </div>
            {!isStockLoading && piezasStatusChecked?.map((pieza) => (
                  <EachCardDetalle
                    key={pieza?.id}
                    pieza={pieza}
                    isCasco={pieza?.casco !== 0}
                    className={className}
                    isPartsSusceptibleWearingCasco={isPartsSusceptibleWearingCasco}
                    piezasStatusChecked={piezasStatusChecked}
                    setPiezasStatusChecked={setPiezasStatusChecked}
                  />))
              }
              { cart?.piezas?.length === 0 &&
                <div className='detalle-carrito__no-data'>
                    <span>{t('carrito.detalle.no-data')}</span>
                </div>}
              {
                isStockLoading && cart?.piezas?.map(pieza => <SkeletonDetalleCarrito key={pieza?.id}/>)
              }
        </div>
  )
}

DetalleCarrito.propTypes = {
  className: PropTypes.string,
  piezasStatusChecked: PropTypes.array.isRequired,
  setPiezasStatusChecked: PropTypes.any.isRequired,
  isPartsSusceptibleWearingCasco: PropTypes.bool.isRequired
}

export default DetalleCarrito
