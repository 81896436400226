import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { orderPartNotFound } from '../../../services/api'
import { MdOutlineMail, MdWhatsapp } from 'react-icons/md'
import ModalSendEmail from '../../MainLayout/CatalogoPiezas/ModalSendEmail'

const NoDataBtns = ({ pieza = {}, className = '', filterValues = {}, searchParams = '', isNotPrice = false, setIsModalSendEmailOpen = () => {} }) => {
  const { t } = useTranslation()
  const user = useSelector(state => state.auth)
  const [isOpenModalSendEmail, setIsOpenModalSendEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { marca, modelo, familia, articulo } = filterValues

  const handleSearchText = () => {
    const searchText = []

    if (marca?.nombre) searchText.push(`marca: ${marca.nombre}`)
    if (modelo?.nombre) searchText.push(`modelo: ${modelo.nombre}`)
    if (familia?.nombre) searchText.push(`familia: ${familia.nombre}`)
    if (articulo?.nombre) searchText.push(`tipo pieza: ${articulo.nombre}`)
    if (searchParams) searchText.push(`referencia: ${searchParams}`)

    const text1 = isNotPrice ? t('catalogo-piezas.no-data.no-price.1') : t('catalogo-piezas.no-data.text-to-send.1')
    const text2 = t('catalogo-piezas.no-data.text-to-send.2')
    return `${text1} ${!isNotPrice ? searchText?.join(', ') : `id: ${pieza?.id}`}, ${text2}`
  }

  const handleSendWhatsapp = (e) => {
    e.stopPropagation()
    const searchText = handleSearchText()
    window.open(
      `https://api.whatsapp.com/send/?phone=34644300171&text=${searchText}`
    )
  }

  const handleSendEmail = async (data) => {
    setIsLoading(true)
    const res = await orderPartNotFound(data)
    if (res?.status === 200) {
      toast.success(t('success.contact.1'))
    } else {
      toast.error(t('errors.1'))
    }
    setIsOpenModalSendEmail(false)
    setIsLoading(false)
  }

  const handleCloseModal = () => {
    setIsModalSendEmailOpen(false)
    setIsOpenModalSendEmail(false)
  }
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div className={className}>
        <span className={`${className}--whats`} onClick={handleSendWhatsapp} >
          <MdWhatsapp/>
        </span>
        <span className={`${className}--email`} onClick={(e) => {
          e.stopPropagation()
          setIsModalSendEmailOpen(true)
          setIsOpenModalSendEmail(true)
        }}>
          <MdOutlineMail/>
        </span>
      </div>
      {isOpenModalSendEmail &&
       <ModalSendEmail
         handleSendEmail={handleSendEmail}
         isLoading={isLoading}
         setIsOpenModalSendEmail={handleCloseModal}
         formValues={{ nombre: user?.nombre, telefono: user?.telefono, email: user?.email, mensaje: handleSearchText() }}
        />}
    </div>
  )
}

NoDataBtns.propTypes = {
  pieza: PropTypes.object,
  className: PropTypes.string,
  filterValues: PropTypes.object,
  searchParams: PropTypes.string,
  isNotPrice: PropTypes.bool,
  setIsModalSendEmailOpen: PropTypes.func
}

export default NoDataBtns
