import { types } from '../types'

export const updateFavorites = (favoritesData) => ({
  type: types.updateFavorites,
  payload: favoritesData
})

export const addToFavorites = (pieza) => ({
  type: types.addToFavorites,
  payload: pieza
})

export const removeToFavorites = (piezaId) => ({
  type: types.removeToFavorites,
  payload: piezaId
})
