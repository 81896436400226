import '../../ResetPassword/resetPassword.scss'

import { useTranslation } from 'react-i18next'
import FormRegister from './FormRegister'

const Register = () => {
  const { t } = useTranslation()

  return (
    <>
        <div className='register reset-password'>
          <div className='register__container'>
            <h1 className='register__container--title'>{t('register.title')}</h1>
            <FormRegister/>
          </div>
        </div>
    </>
  )
}

export default Register
